import { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { IFnbMenuStructure, optionDisplayLocation } from ".";
import { ApiService, ApiShowError, ApiUploadFiles, base64ToFile, changeDataModify, formatOptions, getValueId, isBase64, removeAccents, sortWithAny } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { CustomHeader, FormSubmit, KTFormItem, KTTable, PaginationCustom, SearchTable, TextFormByUser } from "../../../../theme/partials";
import { apiFnb } from "../../../modules/api/fnb";
import { popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import useTreeOpenHandler from "./useTreeOpenHandler";
import styles from "./styles.module.css";
import {
    Tree,
    getBackendOptions,
    MultiBackend,
    NodeModel,
    DropOptions,
    getDescendants,
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { ExternalNode } from "./ExternalNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { NativeTypes } from "react-dnd-html5-backend";
import Node from "./Node";
import Placeholder from "./Placeholder";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx";
import { Column } from "react-table";
import { apiAd } from "../../../modules/api/admin";
import { getIsPermission } from "../../../modules/auth/Permission";
const defaultValues: IFnbMenuStructure = {
    code: null,
    displaylocation: 'secondary-menu',
    name: null,
    active: false,
    ismerchantdisplay: false
}

export function FNBMenuStructuresForm(props: any) {
    const urlApi = 'fnb/menustructures'
    const { permission } = props
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const [initialOpen, setInitialOpen] = useState(false)
    const [pageOption, setPageOption] = useState({ page: 0, limit: 10 })
    const { data: objectData, isSuccess: isObjectData } = apiAd.useObjectsByNumber(permission)
    const arrObject: any = isObjectData ? objectData[0] : undefined
    const permisstiontext = 'modify'
    const isPermission = permission
        ? getIsPermission([permission, permisstiontext.toUpperCase()])
        : true
    let schema = {
        name: yup.string()
            .required('Input is required'),
    }
    const yupSchema = yup.object().shape(schema)

    const { data, isSuccess, isFetching } = apiFnb.useMenuStructuresId<any>(valueid, '?expand=menustructurelines')
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<IFnbMenuStructure>({ defaultValues, resolver: yupResolver(yupSchema) })
    const [treeData, setTreeData] = useState<any>([]);
    useEffect(() => {
        if (data && isSuccess && !isFetching) {
            getData(data)
        }

    }, [isFetching])
    const getData = (data: any) => {
        reset({
            id: data.id,
            code: data.code,
            name: data.name,
            active: data.active,
            displaylocation: data.displaylocation,
            ismerchantdisplay: data.ismerchantdisplay
        })
        if (data && data.displaylocation) {
            setSmartOrder(true)
        }
        let { MenuStructureLines } = data
        MenuStructureLines?.forEach((item: any, index: number) => {
            item.droppable = true
            item.text = item.navigationlabel
            item.id = Number(item.menukey)
            item.parent = item.parent ? Number(item.parent) : 0
            if (item.customlinkinfo) {
                item.url = item.customlinkinfo.url
                item.image = item.customlinkinfo.image
            }
        })
        // console.log('MenuStructureLines',MenuStructureLines)
        setTreeData(MenuStructureLines)
        setInitialOpen(true)
    }
    const { data: dataCategories, isFetching: isCategories } = apiFnb.useCategories()
    const { data: dataSubCategories, isFetching: isSubCategories } = apiFnb.useSubCategories()
    const { data: dataItems, isFetching: isItems } = apiFnb.useHospitalityItems()
    const [nodeCates, setNodeCates] = useState<any>({})
    const [nodeSubCates, setNodeSubCates] = useState<any>({})
    const [nodeItems, setNodeItems] = useState<any>({})
    const formatExternalNodes = (label: string, type: string, dataCate: any, order: number) => {
        let data = dataCate.map((e: any) => ({ ...e, "droppable": true, highlightfirstitem: false, menuitemtype: type, text: e.name }))
        let temp = {
            label: label,
            type: type,
            order: order,
            data: data,
        }
        return temp
    }
    const CustomLinksType = "CustomLinks"
    let dataLinks = [{
        name: "Custom Link",
        text: "Custom Link",
        code: CustomLinksType,
        url: "#",
        type: CustomLinksType,
        id: "customlink",
    }]
    let customLinks = formatExternalNodes('Custom Links', CustomLinksType, dataLinks, 3)
    const [externalNodes, setExternalNodes] = useState<any>([customLinks])
    const [externalArrays, setExternalArrays] = useState<any>([])
    useEffect(() => {
        let tempData = externalNodes.map((item: any) => {
            return {
                type: item.type,
                data: item.data
            }
        })
        // console.log('tempData',tempData)
        setExternalArrays(tempData)
    }, [externalNodes])
    useEffect(() => {
        if (dataSubCategories && !isSubCategories) {
            let subCates = formatExternalNodes('Sub-Categories', 'Subcategory', dataSubCategories, 2)

            // let tempExternalNodes:any = JSON.parse(JSON.stringify(externalNodes));
            // tempExternalNodes = sortWithAny(tempExternalNodes.concat(subCates),{sort:'order'})
            // setExternalNodes(tempExternalNodes)
            setNodeSubCates(subCates)
        }
    }, [isSubCategories])
    useEffect(() => {
        if (dataCategories && !isCategories) {
            let cates = formatExternalNodes('Categories', 'Category', dataCategories, 1)
            // let tempExternalNodes:any = JSON.parse(JSON.stringify(externalNodes));
            // tempExternalNodes = sortWithAny(tempExternalNodes.concat(cates),{sort:'order'})
            // console.log('tempExternalNodes',tempExternalNodes)
            // setExternalNodes(tempExternalNodes)
            setNodeCates(cates)
        }
    }, [isCategories])
    useEffect(() => {
        if (dataItems && !isItems) {
            let items = formatExternalNodes('Items', 'Item', dataItems, 4)

            // let tempExternalNodes:any = JSON.parse(JSON.stringify(externalNodes));
            // tempExternalNodes = sortWithAny(tempExternalNodes.concat(items),{sort:'order'})
            // setExternalNodes(tempExternalNodes)
            setNodeItems(items)
        }
    }, [isItems])


    const handleDelete = (id: NodeModel["id"]) => {
        const deleteIds = [
            id,
            ...getDescendants(treeData, id).map((node) => node.id)
        ];
        const newTree = treeData.filter((node: any) => !deleteIds.includes(node.id));

        setTreeData(newTree);
    };
    const checkNotAllow = (start: any, end: any) => {
        let check = false
        if (start?.parent == end?.id && (start?.menuitemtype == "Category" && (end?.menuitemtype == "Subcategory" || end?.menuitemtype == "Item"))) {
            check = true
        }
        if (start?.parent == end?.id && (start?.menuitemtype == "Subcategory" && end?.menuitemtype == "Item")) {
            check = true
        }
        if (start?.parent == end?.id && (start?.menuitemtype == "Item" && end?.menuitemtype == "Item")) {
            check = true
        }
        // console.log('check', check)
        return check
    }
    const handleDrop = (newTree: NodeModel[], e: DropOptions) => {
        let treeDataDrop = JSON.parse(JSON.stringify(treeData));
        let { dragSourceId, dropTargetId, destinationIndex, monitor } = e as any;

        const end = treeDataDrop.find((v: any) => v.id === dropTargetId);

        // console.log("e",e)
        if (typeof dragSourceId === "undefined" && typeof dropTargetId !== "undefined") {
            // const itemType = monitor.getItemType();
            const nodeJson = monitor.getItem().text;
            const node = JSON.parse(nodeJson) as any;

            node.parent = dropTargetId;
            node.menuobjectid = node.id
            node.menuobjectcode = node.code
            node.id = Date.now();
            dragSourceId = node.id
            // console.log('node',node)
            treeDataDrop = [...newTree, node]
            if (checkNotAllow(node, end)) {
                popupMessage({ icon: 'error', description: 'not allow' })
                return
            }
            setTreeData(treeDataDrop);
        }
        if (typeof dropTargetId === "undefined" || typeof dragSourceId === "undefined") return
        const start = treeDataDrop.find((v: any) => v.id === dragSourceId);

        if (
            start?.parent === dropTargetId &&
            start &&
            typeof destinationIndex === "number"
        ) {
            setTreeData((treeData: any) => {
                const output = reorderArray(
                    treeDataDrop,
                    treeDataDrop.indexOf(start),
                    destinationIndex
                );
                return output;
            });
        }

        if (
            start?.parent !== dropTargetId &&
            start &&
            typeof destinationIndex === "number"
        ) {
            if (
                getDescendants(treeData, dragSourceId).find(
                    (el: any) => el.id === dropTargetId
                ) ||
                dropTargetId === dragSourceId ||
                (end && !end?.droppable)
            )
                return;
            start.parent = dropTargetId
            if (start && end && checkNotAllow(JSON.parse(JSON.stringify(start)), JSON.parse(JSON.stringify(end)))) {
                popupMessage({ icon: 'error', description: 'not allow' })
                return
            }
            setTreeData((treeData: any) => {
                const output = reorderArray(
                    treeDataDrop,
                    treeDataDrop.indexOf(start),
                    destinationIndex
                );
                const movedElement = output.find((el) => el.id === dragSourceId);
                if (movedElement) movedElement.parent = dropTargetId;
                return output;
            });
        }
    };
    //   const handleAddExternalNode = () => {
    //     const node: any = {
    //       id: lastId,
    //       parent: 0,
    //       text: `External node ${lastId - 100}`
    //     };

    //     setExternalNodes([...externalNodes, node]);
    //     setLastId(lastId + 1);
    //   };
    const { ref, getPipeHeight, toggle } = useTreeOpenHandler();
    const handleTextChange = (id: NodeModel["id"], value: string, props?: any) => {
        const { url, highlightfirstitem, image, inputslug, uisettings } = props
        const newTree = treeData.map((node: any) => {
            if (node.id === id) {
                return {
                    ...node,
                    text: value,
                    url: url,
                    highlightfirstitem: highlightfirstitem,
                    uisettings: uisettings,
                    image: image,
                    inputslug: inputslug
                };
            }

            return node;
        });

        setTreeData(newTree);
    };
    const reorderArray = (
        array: NodeModel[],
        sourceIndex: number,
        targetIndex: number
    ) => {
        const newArray = [...array];
        const element = newArray.splice(sourceIndex, 1)[0];
        newArray.splice(targetIndex, 0, element);
        return newArray;
    };
    const findIdMenuParent = (parentid: any) => {
        return treeData.find((item: any) => item.id == parentid)?.menuobjectid
    }
    const onSubmit = async (data: any) => {
        try {
            let MenuStructureLines = [] as any
            console.log('treeData', treeData)
            treeData.forEach((item: any, index: number) => {
                let customlinkinfo = null
                if (item.url) {
                    customlinkinfo = {
                        "url": item.url,
                        "image": item.image,
                    }
                }
                let temp = {
                    "statuscode": 1,
                    "menustructureid": null,
                    "menuitemtype": item.menuitemtype,
                    "menuobjectid": item.menuitemtype == CustomLinksType ? undefined : item.menuobjectid,
                    "menuobjectcode": item.menuitemtype == CustomLinksType ? undefined : item.menuobjectcode,
                    "navigationlabel": item.text,
                    "slug": item.inputslug,
                    "menukey": item.id,
                    "parent": item.parent,//findIdMenuParent(item.parent),
                    "order": index,
                    "highlightfirstitem": item.highlightfirstitem,
                    "uisettings": item.uisettings,
                    customlinkinfo: customlinkinfo,
                }
                MenuStructureLines.push(temp)
            })
            if (data.id) {
                const uploads = await Promise.all(MenuStructureLines
                    .filter((f: any) => !!f.customlinkinfo && isBase64(f.customlinkinfo.image))
                    .map(async (item: any) => {
                        const file = base64ToFile(item.customlinkinfo.image)
                        const res = await ApiUploadFiles(data.id, 'MENU', [file])
                        return { ...item, customlinkinfo: { ...item.customlinkinfo, image: res[0].url } }
                    }))
                console.log('uploads1', uploads)
                MenuStructureLines = MenuStructureLines.map((m: any) => {
                    const temp = uploads.find((f: any) => f.menukey == m.menukey)
                    if (temp) {
                        return temp
                    }
                    return m
                })
            }
            delete data.image
            data.MenuStructureLines = MenuStructureLines
            let nosericode = arrObject?.nosericode && !isEdit ? `/?nosericode=${arrObject?.nosericode}` : ''
            const res = await ApiService.post(`${urlApi}/upsertwithnewdetails${nosericode}`, changeDataModify(data))
            if (!data.id && res.data.id) {
                const uploads = await Promise.all(MenuStructureLines
                    .filter((f: any) => !!f.customlinkinfo && isBase64(f.customlinkinfo.image))
                    .map(async (item: any) => {
                        const file = base64ToFile(item.customlinkinfo.image)
                        const res = await ApiUploadFiles(data.id, 'MENU', [file])
                        return { ...item, customlinkinfo: { ...item.customlinkinfo, image: res[0].url } }
                    }))
                console.log('uploads2', uploads)
                MenuStructureLines = MenuStructureLines.map((m: any) => {
                    const temp = uploads.find((f: any) => f.menukey == m.menukey)
                    if (temp) {
                        return temp
                    }
                    return m
                })
                data.MenuStructureLines = MenuStructureLines
                await ApiService.post(`${urlApi}/upsertwithnewdetails${nosericode}`, changeDataModify(data))
            }
            popupMessage({ icon: 'success', autoClose: true })
            if (!valueid) navigate('/fnb/menu-structures')
        } catch (error) {
            ApiShowError(error)
        }
    }
    const renderExternalNode = (data: any) => {
        const { type, results } = data
        let tempData = JSON.parse(JSON.stringify(externalNodes));
        let indexChange = tempData.findIndex((node: any) => node.type == type)
        if (indexChange > -1) {
            tempData[indexChange].data = results
        }
        setExternalArrays(tempData)
    }
    const columns: Array<Column> = [{
        Header: (props) => <CustomHeader tableProps={props} title='' />,
        accessor: 'name',
        Cell: ({ ...props }) => <ExternalNode node={props.data[props.row.index]} />,
    }]
    const intl = useIntl()
    const renderFrameTab = (title: string, item: any, index: string) => {
        return <Accordion.Item eventKey={'header' + index} className="border-0 hide-dropdown-after" key={index}>
            <Accordion.Header as="div" className="accordion-head-nocss bg-dark" onClick={() => document.getElementById(`nav-header` + index)?.classList.toggle('fa-rotate-90')}>
                <div className="d-flex align-items-center w-100 p-3">
                    <span className="text-white fs-4 fw-bold">{title}</span>
                    <i id={`nav-header` + index} className="fa-solid fa-chevron-right ms-auto"></i>
                </div>
            </Accordion.Header>
            <Accordion.Body className="p-0 py-2 d-flex flex-column gap-1 table-tab-move">
                {item.data && item.data?.length != 0 &&
                    <KTTable
                        data={item.data}
                        columns={columns}
                        search={item.type != CustomLinksType}
                        pagination={item.type != CustomLinksType}
                        setPageOption={setPageOption}
                        pageOption={pageOption}
                        isHideSelectPage
                        className="p-0"
                        classWrap=""

                    />
                }
                {/* <div>
                {externalArrays[index]?.data?.map((node:any) => (
                    <ExternalNode key={node.id} node={node} />
                ))}
               
            </div> */}
            </Accordion.Body>
        </Accordion.Item>
    }
    const [smartOrder, setSmartOrder] = useState(false)
    return <>
        <ContentHeader title={'sidebar.fnb.menu-structures'} items={[{ title: 'sidebar.fnb.menu-structures', path: '/fnb/menu-structures' }]} />
        <div className="row h-100">
            <div className="col-12 col-sm-3">
                <Card>
                    <Card.Header>
                        <Card.Title>{intl.formatMessage({ id: "text.add-menu-items" as any })}</Card.Title>
                    </Card.Header>
                    <Accordion className="d-flex flex-column gap-2" alwaysOpen>
                        {nodeCates && renderFrameTab("Categories", nodeCates, "1")}
                        {nodeSubCates && renderFrameTab("Sub-Categories", nodeSubCates, "2")}
                        {customLinks && renderFrameTab("Custom Links", customLinks, "3")}
                        {nodeItems && renderFrameTab("Items", nodeItems, "4")}
                    </Accordion>
                </Card>
            </div>
            <div className="col-12 col-sm-9">
                <div className="card wrap-content-sticky">
                    <form
                        action=""
                        className="form"
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-header">
                            <h3 className="card-title"><FormattedMessage id="text.general" /></h3>
                            <div className="card-toolbar">
                                <TextFormByUser data={watch()} />
                                <FormSubmit
                                    type={isEdit}
                                    isSubmitting={isSubmitting}
                                    permission={permission}
                                    handleClose={() => { navigate('/fnb/menu-structures') }}
                                />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='text.code' >
                                        <input disabled className={`form-control form-control-sm ${errors.code && 'form-error'}`} {...register('code')} />
                                    </KTFormItem>
                                    <KTFormItem title='text.request.name' isRequired>
                                        <input className={`form-control form-control-sm ${errors.name && 'form-error'}`} {...register('name')} />
                                    </KTFormItem>
                                    <KTFormItem title='text.active'>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <input className="h-20px w-30px form-check-input" type="checkbox" {...register("active")} />
                                        </label>
                                    </KTFormItem>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <KTFormItem title='Merchant'>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <input className="h-20px w-30px form-check-input" type="checkbox" {...register("ismerchantdisplay")} />
                                        </label>
                                    </KTFormItem>
                                    <KTFormItem title='Smart order'>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <input className="h-20px w-30px form-check-input" type="checkbox" checked={smartOrder} onChange={() => {
                                                if (smartOrder) {
                                                    setSmartOrder(false)
                                                    setValue('displaylocation', null)
                                                } else {
                                                    setSmartOrder(true)
                                                    setValue('displaylocation', 'primary-menu')
                                                }
                                            }} />
                                        </label>
                                    </KTFormItem>
                                    {smartOrder && <KTFormItem title='text.display-location'>
                                        <div className="d-flex gap-2">
                                            {optionDisplayLocation.map(item => <label key={item.value} className="form-check form-check-sm">
                                                <input className="form-check-input" type="radio" value={item.value} {...register("displaylocation")} />
                                                {intl.formatMessage({ id: `text.${item.value}` as any })}
                                            </label>)}
                                        </div>
                                    </KTFormItem>}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-sm-8">
                                <i className="text-gray-600 fw-bold">Add menu items from the column on the left || {treeData.length}</i>
                                <div className="d-flex flex-column gap-2 mt-4">
                                    {/* {isSuccess &&  */}
                                    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                        <div className={clsx(styles.wrapper, 'wrapper-tree', { 'empty': treeData.length == 0 })}>
                                            {
                                                treeData.length == 0 &&
                                                <div className="drag-noti">
                                                    Drag menu in here
                                                </div>

                                            }
                                            <Tree
                                                // initialOpen={initialOpen}
                                                ref={ref}
                                                classes={{
                                                    root: styles.treeRoot,
                                                    placeholder: styles.placeholder,
                                                    dropTarget: styles.dropTarget,
                                                    listItem: styles.listItem
                                                }}
                                                placeholderRender={(node, { depth }) => (
                                                    <Placeholder node={node} depth={depth} />
                                                )}
                                                extraAcceptTypes={[NativeTypes.TEXT]}
                                                listComponent='div'
                                                listItemComponent='div'
                                                tree={treeData}
                                                sort={false}
                                                rootId={0}
                                                insertDroppableFirst={false}
                                                enableAnimateExpand={true}
                                                onDrop={handleDrop}
                                                canDrop={() => true}
                                                // dropTargetOffset={5}
                                                dragPreviewRender={(monitorProps: any) => (
                                                    <CustomDragPreview monitorProps={monitorProps} />
                                                )}
                                                render={(node, { depth, isOpen, isDropTarget }) => (
                                                    <Node
                                                        getPipeHeight={getPipeHeight}
                                                        handleDelete={handleDelete}
                                                        node={node}
                                                        depth={depth}
                                                        isOpen={isOpen}
                                                        isPermission={isPermission}
                                                        onClick={() => {
                                                            if (node.droppable) {
                                                                toggle(node?.id);
                                                            }
                                                        }}
                                                        onTextChange={handleTextChange}
                                                        isDropTarget={isDropTarget}
                                                        treeData={treeData}
                                                        control={control}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </DndProvider>
                                    {/* } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}