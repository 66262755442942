import CryptoJS from "crypto-js";
import { loadMessageI18n, popupLoading, popupMessage, popupQuestion } from "../../app/modules/messages";
import { ApiService } from "./ApiService";
import { removeAccents } from "./AssetHelpers";



const key = process.env.REACT_APP_PRIVATE_KEY

export const clientTime = {
    nowDate: new Date(),
    nowDateString: new Date().toLocaleDateString(),
    nowTimeNumber: new Date().getTime()
}

export function generateSecureShortCode(id: string) {
    const salt = CryptoJS.lib.WordArray.random(4).toString(CryptoJS.enc.Hex); // Tạo salt 4 byte
    if (!process.env.REACT_APP_SECRET_KEY) {
        return { hash: id, salt };
    }
    const rawString = `${id}!${salt}`;
    const hash = CryptoJS.HmacSHA256(rawString, process.env.REACT_APP_SECRET_KEY as string).toString(CryptoJS.enc.Hex);
    return { hash, salt };
}

export const encryptData: (data: string, code?: string) => string | null = (data, code) => {
    if (!key) return null;
    // console.log('data',data)
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export const decryptData: (ciphertext: string, code?: string) => string | null = (ciphertext, code) => {
    if (!key) return null;
    const bytes = CryptoJS.AES.decrypt(ciphertext, key).toString(CryptoJS.enc.Utf8)
    // console.log('bytes',bytes)
    return bytes ? JSON.parse(bytes) : null;
}

export async function ApiShowError(error: any) {
    // 403-284210010 token invalid
    // console.log(error)
    const { errorCode, errorMessage, name, statusCode } = error.response?.data
    if (errorCode == '403-284210010') {
        await popupMessage({ icon: 'info', description: loadMessageI18n({ key: "message.error.token-expired" }), confirmButtonText: loadMessageI18n({ key: "text.return-login" }) })
        window.location.href = '/login'
    }
    popupMessage({ icon: 'error', description: error.response?.data?.errorMessage })
}

export async function ApiUploadFiles(uploadcode: string, category: string, files: File[], isMessage?: boolean) {
    if (!files || files.length == 0) {
        popupMessage({ icon: "error", description: "Invalid files." })
        return undefined
    }
    const formData = new FormData();
    formData.append('uploadcode', uploadcode)
    formData.append('category', category.toUpperCase())
    files.forEach((file: any) => {
        let tempPre = file.name.split('.')
        let tempName = [removeAccents(tempPre[0]), tempPre[1]].filter((f: any) => f).join('.')
        formData.append('file', new File([file], tempName))
    })
    try {
        const results = await ApiService.post(`utilities/uploadfiles`, formData)
        if (results) {
            isMessage && popupMessage({ icon: 'success', autoClose: true })
            return results.data.uploadedfiles
        }
        return undefined
    } catch (error: any) {
        ApiShowError(error)
    }
}

export async function ApiDeleteFile(pathtofile: string) {
    try {
        await ApiService.post(`utilities/deletefile`, { pathtofile: pathtofile })
    } catch (error) {
        ApiShowError(error)
    }
}

export function getLocalStorage<T>(key: string) {
    const ls = localStorage.getItem(key)
    if (ls) {
        try {
            return JSON.parse(ls) as T
        } catch (er) {
            console.error(er)
        }
    }
    return undefined
}

export function setLocalStorage(key: string, values: any) {
    localStorage.setItem(key, JSON.stringify(values))
}

export function changeDataModify(data: any) {
    delete data.createdat
    delete data.createdby
    delete data.modifiedat
    delete data.modifiedby
    delete data.modifier
    delete data.creater
    return data
}

export async function syncObjects(url: string, callback: () => void) {
    const question = await popupQuestion({ description: 'Confirm synchronization of this table data with bc.' })
    if (question) {
        popupLoading()
        try {
            await ApiService.get(url)
            popupMessage({ icon: "success", autoClose: true })
        } catch (error) {
            ApiShowError(error)
        }
        callback()
    }
}


//===================================


const isClientSide = (): boolean => typeof window !== 'undefined';
export function clearUserData() {
    if (checkIsAuth()) {
        console.error('Clear local');
        removeCookie('token');
        removeCookie('userid');
        if (isClientSide()) {
            localStorage.clear();
            window.location.href = '/login';
        }
    }
}

export function checkIsAuth() {
    return getCookie('token') !== undefined;
}
export const getCookie = (name: string) => {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
}

export const setCookie = (name: string, value: string, days: number) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    return 0
}

export const removeCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    return 0
}

