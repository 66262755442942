import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Image, Modal, Tab, Tabs } from "react-bootstrap";
import ReactDataSheet from "react-datasheet";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useQueryClient } from "react-query";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";
import { IHospDiningAreaSection, IHospDiningTable, IHospStaff, ISMerchant, ISMerchantPrinter } from ".";
import { ApiDeleteFile, ApiService, ApiShowError, ApiUploadFiles, base64ToFile, changeDataModify, debounce, downloadFile, formatOptions, generateQRCode, generateSecureShortCode, getValueId, radomString, sortByNumber, sortWithAny, toBase64 } from "../../../../theme/helpers";
import { ContentHeader } from "../../../../theme/layout/components/content";
import { BasicCell, CustomHeader, ExtendAddressValue, FilterDropdown, FormSubmit, InputDate, InputPrice, InputSelect, KTButton, KTFormItem, KTSVG, KTTable, RadioBoolean, RowIcon, TextFormByUser, yup } from "../../../../theme/partials";
import { apiPrivate } from "../../../modules/api";
import { optionAddress } from "../../../modules/api/address";
import { apiAd } from "../../../modules/api/admin";
import { apiFnb, fetchQuery } from "../../../modules/api/fnb";
import { popupDelete, popupMessage } from "../../../modules/messages";
import { MergedProps } from "../../../router/AppRoutes";
import { IConfigStoreTier } from "../../configuration";
import { getIsPermission } from "../../../modules/auth/Permission";
import SearchPopup from "../../../../theme/partials/layout/search-popup";
import { Reorder } from "framer-motion"
import { object, string } from "yup";

const defaultValues: ISMerchant = {
    storecode: null,
    storename: null,
    merchantkey: null,
    merchanturl: null,
    merchanturlpublic: false,
    address: null,
    ward: null,
    district: null,
    zipcode: null,
    province: null,
    state: null,
    country: null,
    phoneno: null,
    email: null,
    latitude: null,
    longitude: null,
    countryphonecode: null,
    timezonecode: null,
    displaymode: null,
    thousandseparator: null,
    decimalseparator: null,
    showitempicture: false,
    autoacceptorder: false,
    allowreceiptprinter: false,
    allowkitchenprinter: false,
    allowreprint: false,
    notificationviazalo: false,
    notificationviasms: false,
    notificationviaemail: false,
    smartwebsplashscreen: false,
    guestmodelogin: false,
    enableOTP: false,
    storetiercode: null,
    menustructuretiercode: null,
    timebasedmenutiercode: null,
    paymenttiercode: null,
    storetierid: null,
    menustructuretierid: null,
    timebasedmenutierid: null,
    paymenttierid: null,
    posterminalcode: null,
    vatbuspostinggroup: null,
    customerno: null,
    genbuspostinggroup: null,
    taxidentificationno: null,
    requestnoofguest: false,
    useseatingcapacity: false,
    coverexceedcapacity: false,
    enableordertype: [],
    active: false,
    allowpopupcustomization: false,
    onmodifyorder: null,
    brandingcustomization: {
        brandaccentcolor: '#000000',
        fonts: {
            heading: {
                typeface: 'Arial',
                color: '#000000'
            },
            body: {
                typeface: 'Arial',
                color: '#000000'
            }
        },
    }
}

const tabList = [
    { title: 'General', ico: 'bi bi-info-circle', width: 80 },
    { title: 'Section And Table', ico: '/media/icons/tabs/dinner-table.svg', width: 150 },
    { title: 'Staff', ico: '/media/icons/tabs/employees.svg', width: 80 },
    { title: 'Order Settings', ico: '/media/icons/tabs/checkout.svg', width: 125 },
    { title: 'QR Code', ico: '/media/icons/tabs/qr-code.svg', width: 100 },
    { title: 'Payment', ico: 'bi bi-credit-card', width: 80 },
    { title: 'Printer Settings', ico: '/media/icons/tabs/printer-_1_.svg', width: 125 },
    { title: 'Production Sections', ico: '/media/icons/tabs/production_section.svg', width: 150 },
    { title: 'Section Disp. Stat. Mappings', ico: '/media/icons/tabs/productiondisplaystationmapping.svg', width: 200 },
    { title: 'Store Price Group', ico: '/media/icons/tabs/info (1).svg', width: 150 },
]

export function SMerchantsForm({ keyElement, pathElement, permission }: MergedProps) {
    const navigate = useNavigate();
    const urlApi = 'fnb/merchants'
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { data, isSuccess, isFetching, refetch } = apiFnb.useMerchantsId<ISMerchant>(valueid)
    const dataMerchant = isSuccess && !isFetching ? data : undefined
    const [tabListId, setTabListId] = useState('General')

    function renderSwitch() {
        const options = { dataMerchant, urlApi, refetch, navigate, permission }
        if (tabListId == 'General') {
            return <GeneralForm key={tabListId} {...options} />
        } else if (dataMerchant) {
            switch (tabListId) {
                case 'Payment':
                    return <PaymentForm key={tabListId} {...options} />
                case 'Section And Table':
                    return <SectionAndTableForm key={tabListId} {...options} />
                case 'Staff':
                    return <StaffForm key={tabListId} {...options} />
                case 'QR Code':
                    return <QRCodeForm key={tabListId} {...options} />
                case 'Order Settings':
                    return <OrderSettingsForm key={tabListId} {...options} />
                case 'Printer Settings':
                    return <PrinterSettingsForm key={tabListId} {...options} />
                case 'Production Sections':
                    return <ProductionSectionsForm key={tabListId} {...options} />
                case 'Section Disp. Stat. Mappings':
                    return <SectionDispStatMappingsForm key={tabListId} {...options} />
                case 'Store Price Group':
                    return <StorePriceGroup key={tabListId} {...options} />
                default:
                    return null;
            }
        }
    }
    const [clientWidth, setClientWidth] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null)
    const getDropdownMenu = useCallback((index: number) => {
        // console.log(1)
        const widthContainer = ref.current?.clientWidth
        return (tabList.filter((_, int) => int <= index).reduce((acc, curr) => acc + curr.width, 0)) + 30 > widthContainer!
    }, [clientWidth])

    useEffect(() => {
        const element = ref.current;

        // Hàm cập nhật clientWidth khi element thay đổi kích thước
        const updateWidth = () => {
            if (element) {
                setClientWidth(element.clientWidth);
            }
        };

        // Tạo một observer để theo dõi sự thay đổi kích thước
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup observer khi component bị unmount
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);

    return <>
        <ContentHeader
            isInfo
            title={keyElement}
            items={[{ title: keyElement, path: pathElement }]}
            elements={<KTButton action="back" onClick={() => navigate(pathElement)} />} />
        <div className="card">
            <div className="bg-secondary rounded-top px-3 py-2">
                <div ref={ref} className="d-flex align-items-center">
                    {tabList.map((tab, index) => {
                        const isActive = tabListId == tab.title
                        const inDropdownMenu = getDropdownMenu(index)

                        return <button
                            key={index}
                            type="button"
                            onClick={() => setTabListId(tab.title)}
                            className={clsx('btn px-3 py-2 d-flex flex-column align-items-center', {
                                'bg-orange text-white': isActive,
                                'disabled': !isEdit && (tab.title != 'General'),
                                'd-none': inDropdownMenu
                            })}
                            style={{ minWidth: tab.width }}>
                            {tab.ico.startsWith('bi ')
                                ? <i className={clsx('fs-2', tab.ico, isActive ? 'text-white' : 'text-dark')}></i>
                                : <KTSVG path={tab.ico} className={clsx(`svg-icon-2 m-0`, isActive ? 'svg-icon-white' : 'svg-icon-dark')} />
                            }
                            <div className="text-nowrap fs-6">
                                {tab.title}
                                {/* <FormattedMessage id={`text.${text.title.toLowerCase().replaceAll(" ", "-")}` as any} /> */}
                            </div>
                        </button>
                    })}
                    {getDropdownMenu(tabList.length - 1) && <Dropdown className="ms-auto">
                        <Dropdown.Toggle as="button" className="btn btn-sm btn-link h-el-after px-3 py-2 min-w-30px w-30px">
                            <i className="bi bi-three-dots fs-2"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {tabList.map((tab, index) => {
                                const isActive = tabListId == tab.title
                                const inDropdownMenu = getDropdownMenu(index)
                                if (!inDropdownMenu) return null
                                return <Dropdown.Item key={index} onClick={() => setTabListId(tab.title)} className={clsx({ 'active': isActive })}> {tab.title} </Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>}
                </div>
            </div>
            <div className="card-body">
                {renderSwitch()}
            </div>
        </div>
        <InputDate className="d-none" value={undefined} onChange={(e) => { }} />
    </>
}

interface IDataTab {
    dataMerchant?: ISMerchant
    urlApi: string
    refetch: () => void
    navigate: NavigateFunction
    permission?: string
}

export const optionVatBusPostingGroups = [
    { value: "NO-VAT", label: "NO-VAT Không thuế" },
    { value: "OVERSEA", label: "OVERSEA Nước ngoài" },
    { value: "T", label: "T" },
    { value: "V", label: "V" },
    { value: "VAT-DLC", label: "VAT-DLC Thuế GTGT CN Buôn Ma Thuột" },
    { value: "VAT-HAN", label: "VAT-HAN Thuế GTGT CN Hà Nội" },
    { value: "VAT-HCM", label: "VAT-HCM Thuế GTGT CN HCM" },
    { value: "VAT-KHA", label: "VAT-KHA Thuế GTGT CN Khánh Hòa" },
]

export const optionOnModifyOrders = [
    { value: 'transaction_multi_kot', label: 'Add item to new KOT' },
    { value: 'transaction_one_kot', label: 'Add item to same KOT' }
]

function GeneralForm({ dataMerchant, urlApi, refetch, navigate, permission }: IDataTab) {
    const { isEdit, valueid } = getValueId(dataMerchant?.id)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<ISMerchant>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            storecode: yup.string().required(),
            storename: yup.string().required(),
            merchantkey: yup.string().required(),
            posterminalcode: yup.string().required(),
            storetierid: yup.string().required(),
            menustructuretierid: yup.string().required(),
            vatbuspostinggroup: yup.string().required(),
            brandingcustomization: yup.object().shape({
                brandaccentcolor: yup.string().test('brandaccentcolor', 'Color is not valid', (val) => isValidColor(val)),
                fonts: yup.object().shape({
                    heading: yup.object().shape({
                        typeface: yup.string().nullable(true).transform(val => val || null),
                        color: yup.string().required()
                    }),
                    body: yup.object().shape({
                        typeface: yup.string().nullable(true).transform(val => val || null),
                        color: yup.string().required()
                    })
                })
            }).nullable(true)
        })),
    })

    useEffect(() => {
        if (isEdit && dataMerchant) {
            reset({ ...dataMerchant })
        } else {
            reset({
                merchantkey: radomString(7).toUpperCase(),
                brandingcustomization: {
                    brandaccentcolor: '#000000',
                    fonts: {
                        heading: {
                            typeface: null,
                            color: '#000000'
                        },
                        body: {
                            typeface: null,
                            color: '#000000'
                        }
                    }
                }
            })
        }
    }, [dataMerchant])

    const { data: dataStores, isSuccess: isStores, isLoading: loadStores } = apiPrivate.useStores()
    const optionStores = isStores ? formatOptions(dataStores, ['code', 'code', 'storename']) : []
    const { data: dataStoreTiers, isSuccess: isStoreTiers } = apiAd.useStoreTiers()
    const { data: dataMenuStructureTiers, isSuccess: isMenuStructureTiers } = apiAd.useMenuStructureTiers()
    const { data: dataTimeBasedMenuTiers, isSuccess: isTimeBasedMenuTiers } = apiFnb.useTimeBasedMenus() //useTimeBasedMenuTiers
    const optionStoreTiers = isStoreTiers ? formatOptions(dataStoreTiers, ['id', 'code', 'name']) : []
    const optionMenusTructureTiers = isMenuStructureTiers ? formatOptions(dataMenuStructureTiers, ['id', 'code', 'name']) : []
    const optionTimeBasedMenuTiers = isTimeBasedMenuTiers ? formatOptions(dataTimeBasedMenuTiers, ['id', 'code', 'name']) : []

    async function uploadImage(data: any, merchantId: string) {
        let bodyData: { [key: string]: any } = { ...data }
        if (!!dataMerchant?.brandingcustomization?.logo && (dataMerchant?.brandingcustomization?.logo != data.brandingcustomization?.logo)) {
            const path = 'BRAND-LOGO' + String(dataMerchant?.brandingcustomization?.logo).split('BRAND-LOGO').pop()
            await ApiDeleteFile(path)
        }
        if (data.brandingcustomization && data.brandingcustomization.logo instanceof File) {
            const files = await ApiUploadFiles(merchantId, 'BRAND-LOGO', [data.brandingcustomization.logo])
            if (files[0]) {
                bodyData.brandingcustomization.logo = files[0].url
            }
        }
        return bodyData
    }

    async function onSubmit(data: ISMerchant) {
        let bodyData: { [key: string]: any } = { ...data }
        bodyData.merchanturl = 'https://merchant.tnf.isoftvietnam.com/' + bodyData.merchantkey
        if (dataMerchant?.id) {
            bodyData = await uploadImage(data, dataMerchant.id)
        }
        try {
            const method = isEdit ? 'put' : 'post'
            const result = await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, changeDataModify(bodyData))
            if (!isEdit) {
                bodyData = await uploadImage(data, result.data.id)
                await ApiService.put(`${urlApi}/${result.data.id}`, changeDataModify(bodyData))
            }
            popupMessage({ icon: 'success', autoClose: true })
            refetch()
            !isEdit && navigate('/fnb/merchant-list')
        } catch (error) {
            ApiShowError(error)
        }
    }

    const visibleTos = [
        { value: "online-orders", label: "Online orders" },
        { value: "orders-hub", label: "In-store orders: Orders Hub" },
        { value: "table-service", label: "In-store orders: Table Service" },
        { value: "quick-order", label: "In-store orders: Quick order" },
        { value: "payment-terminal", label: "Payment terminal" },
    ]

    const [imageKey, setImageKey] = useState(Date.now())
    const imageRef = useRef<HTMLInputElement>(null)

    function handleImageChange(event: any) {
        const file = event.target.files[0]
        if (file.size > 2 * 1024 * 1024) {
            popupMessage({
                autoClose: true,
                description: 'The image size is too large, please upload an image smaller than 2MB.',
                icon: 'error'
            })
            return;
        }
        setValue('brandingcustomization.logo', file)
        setImageKey(Date.now())
    }

    const { data: fontsData } = useQuery(
        ['/utilities/readfolderinfo'],
        () => ApiService.post('/utilities/readfolderinfo', { pathtofolder: "STORAGE/FONTS" }).then(res => res.data)
    )
    const fontOptions = (fontsData ?? []).map((font: string) => ({
        value: `https://icmsapi.isoftvietnam.com/STORAGE/FONTS/${font}`,
        label: font
    }))
    const [modalFonts, setModalFonts] = useState(false)

    return <form onSubmit={handleSubmit(onSubmit, error => console.log(error))} className={clsx("row")}>
        <div className="col-12">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <KTFormItem title="text.store.id">
                        <Controller
                            control={control}
                            name="storecode"
                            render={({ field: { value, onChange } }) => <InputSelect
                                isLoading={loadStores}
                                isAsync
                                options={optionStores}
                                value={optionStores.find(f => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                    setValue("storename", e.storename)
                                    setValue("address", e.address)
                                    setValue("ward", e.ward)
                                    setValue("district", e.district)
                                    setValue("zipcode", e.zipcode)
                                    setValue("province", e.province)
                                    setValue("state", e.state)
                                    setValue("country", e.country)
                                    setValue("phoneno", e.phoneno)
                                    setValue("email", e.email)
                                    setValue("latitude", e.latitude)
                                    setValue("longitude", e.longitude)
                                    setValue("vatbuspostinggroup", e.vatbuspostinggroup)
                                }}
                                className={clsx("w-100", { "form-error": errors.storecode })}
                            />}
                        />
                    </KTFormItem>
                    <KTFormItem title="text.name">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.storename })} {...register("storename")} />
                    </KTFormItem>
                    <ExtendAddressValue {...{
                        register,
                        control,
                        errors,
                        setValue,
                        watch
                    }} />
                    <KTFormItem title="text.phone">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.phoneno })} {...register("phoneno")} />
                    </KTFormItem>
                    <KTFormItem title="text.email">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.email })} {...register("email")} />
                    </KTFormItem>
                    {/* <KTFormItem title="text.display-mode">
                        <Controller
                            control={control}
                            name="displaymode"
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionDisplayModes}
                                value={optionDisplayModes.find(f => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                            />}
                        />
                    </KTFormItem>
                    <KTFormItem title="text.thousand-separator">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.thousandseparator })} {...register("thousandseparator")} />
                    </KTFormItem> */}
                </div>
                <div className="col-12 col-lg-6">
                    <KTFormItem title="text.merchant-key">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.merchantkey })} {...register("merchantkey")} />
                    </KTFormItem>
                    <KTFormItem title="text.merchant-url">
                        <input disabled className={clsx("form-control form-control-sm", { "form-error": errors.merchanturl })} {...register("merchanturl")} />
                    </KTFormItem>
                    <KTFormItem title="text.public">
                        <RadioBoolean control={control} name="merchanturlpublic" className="form-check form-check-sm form-check-custom min-w-100px" />
                    </KTFormItem>
                    <KTFormItem title="text.latitude">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.latitude })} {...register("latitude")} />
                    </KTFormItem>
                    <KTFormItem title="text.longitude">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.longitude })} {...register("longitude")} />
                    </KTFormItem>
                    <KTFormItem title="text.country-phone-code">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.countryphonecode })} {...register("countryphonecode")} />
                    </KTFormItem>
                    <KTFormItem title="text.timezone">
                        <Controller
                            name="timezonecode"
                            control={control}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionAddress.timeZone}
                                value={optionAddress.timeZone.find(f => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                                className={clsx('w-100', { 'form-error': errors.timezonecode })}
                            />}
                        />
                    </KTFormItem>
                    {/* <KTFormItem title="Auto accept order">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" {...register("autoacceptorder")} />
                        </div>
                    </KTFormItem> */}
                    <KTFormItem title="Reprint">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" {...register("allowreprint")} />
                        </div>
                    </KTFormItem>
                    <KTFormItem title="Allow popup customization">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" {...register("allowpopupcustomization")} />
                        </div>
                    </KTFormItem>
                    {/* <KTFormItem title="text.decimal-separator">
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.decimalseparator })} {...register("decimalseparator")} />
                    </KTFormItem> */}
                </div>
            </div>
        </div>
        <div className="col-12">
            <div className="mb-4">
                <div className="card-header px-0 pt-0 border-bottom-0">
                    <div className="card-title"><FormattedMessage id="text.advance-settings" /></div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.store-tier'>
                        <Controller
                            control={control}
                            name={`storetierid`}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionStoreTiers}
                                value={optionStoreTiers.find(f => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                    setValue('storetiercode', e.code)
                                }}
                                className={clsx('w-100', { 'form-error': errors.storetierid })}
                            />}
                        />
                    </KTFormItem>
                    <KTFormItem title='Terminal'>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.posterminalcode })} {...register("posterminalcode")} />
                    </KTFormItem>
                    <KTFormItem title='Visible to' isHeight>
                        <div className="d-flex flex-column gap-3">
                            {visibleTos.map((item, index) => <div key={index} className="form-check">
                                <input
                                    id={item.value}
                                    className="form-check-input"
                                    type="checkbox"
                                    value={item.value}
                                    checked={typeof watch('visibleto') === 'string' && (watch('visibleto') as string).indexOf(item.value) > -1}
                                    onChange={(event) => {
                                        let array = watch('visibleto') ? (watch('visibleto') as string).split(",") : []
                                        if (event.target.checked) {
                                            array.push(item.value)
                                        } else {
                                            array = array.filter(f => f !== item.value)
                                        }
                                        const vals = visibleTos.map(m => m.value)
                                        const _array = array.filter(f => vals.includes(f))
                                        setValue('visibleto', _array.length > 0 ? _array.join(",") : null);
                                    }} />
                                <label htmlFor={item.value} className="form-check-label">{item.label}</label>
                            </div>)}
                        </div>
                    </KTFormItem>
                    <KTFormItem title='Approval mode' isHeight>
                        <div className="d-flex flex-column gap-3">
                            <div className="form-check">
                                <input id="approveManually" className="form-check-input" type="radio" value={`approveManually`} {...register("approvalmode")} />
                                <label className="form-check-label" htmlFor="approveManually">Approve manually</label>
                            </div>
                            <div className="form-check">
                                <input id="sendOrdersDirectlyToKitchen" className="form-check-input" type="radio" value={`sendOrdersDirectlyToKitchen`} {...register("approvalmode")} />
                                <label className="form-check-label" htmlFor="sendOrdersDirectlyToKitchen">Send orders directly to kitchen</label>
                            </div>
                        </div>
                    </KTFormItem>
                </div>
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.menu-structure-tier'>
                        <Controller
                            control={control}
                            name={`menustructuretierid`}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionMenusTructureTiers}
                                value={optionMenusTructureTiers.find((f: any) => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                    setValue('menustructuretiercode', e.code)
                                }}
                                className={clsx('w-100', { 'form-error': errors.menustructuretierid })}
                            />}
                        />
                    </KTFormItem>
                    <KTFormItem title='text.time-based-menu-tier'>
                        <Controller
                            control={control}
                            name={`timebasedmenutierid`}
                            render={({ field: { value, onChange } }) => <InputSelect
                                isClearable
                                options={optionTimeBasedMenuTiers}
                                value={optionTimeBasedMenuTiers.find((f: any) => f.value == value) || null}
                                onChange={(e) => {
                                    onChange(e?.value ?? null)
                                    setValue('timebasedmenutiercode', e?.code ?? null)
                                }}
                            />}
                        />
                    </KTFormItem>
                </div>
            </div>
        </div>
        <div className="col-12">
            <hr />
            <div className="card-header px-0 pt-0 border-bottom-0">
                <div className="card-title">Invoice</div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.vat-bus-posing-group'>
                        <Controller
                            control={control}
                            name={`vatbuspostinggroup`}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionVatBusPostingGroups}
                                value={optionVatBusPostingGroups.find((f: any) => f.value == value) || null}
                                onChange={(e) => {
                                    if (!e) return onChange(null)
                                    onChange(e.value)
                                }}
                                className={clsx('w-100', { 'form-error': errors.vatbuspostinggroup })}
                            />}
                        />
                    </KTFormItem>
                </div>
                <div className="col-12 col-lg-6">
                    <KTFormItem title='text.tax-identification-no'>
                        <input className="form-control form-control-sm" {...register("taxidentificationno")} />
                    </KTFormItem>
                </div>
            </div>
        </div>
        <div className="col-12">
            <hr />
            <div className="card-header px-0 pt-0 border-bottom-0">
                <div className="card-title">KDS</div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <KTFormItem title='On Modify Order'>
                        <Controller
                            control={control}
                            name={`onmodifyorder`}
                            render={({ field: { value, onChange } }) => <InputSelect
                                options={optionOnModifyOrders}
                                value={optionOnModifyOrders.find(f => f.value == value) || null}
                                onChange={(e) => onChange(e?.value ?? null)}
                            />}
                        />
                    </KTFormItem>
                </div>
                <div className="col-12 col-lg-6">

                </div>
            </div>
        </div>
        <div className="col-12">
            <hr />
            <div className="card-header px-0 pt-0 border-bottom-0">
                <div className="card-title">Your Brand</div>
            </div>
            <div className="mb-3">
                <label className="form-label" htmlFor="accentcolor">Brand accent color</label>
                <InputColor
                    value={watch('brandingcustomization.brandaccentcolor')}
                    onChange={val => setValue('brandingcustomization.brandaccentcolor', val)}
                />
            </div>
        </div>
        <div className="mb-3">
            <label className="form-label">Logo</label>
            <p>Your logo will be shown at the top of your website</p>
            <div className="d-flex flex-column gap-3 align-items-start">
                <input key={imageKey} ref={imageRef} style={{ display: 'none' }} type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleImageChange} />
                <div className="w-100px h-100px border border-gray-400">
                    {watch('brandingcustomization.logo')
                        ? <img
                            style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                            src={loadImage(watch('brandingcustomization.logo'))}
                            alt="logo"
                            onClick={() => imageRef.current?.click()}
                        />
                        : <div
                            onClick={() => imageRef.current?.click()}
                            className="d-flex align-items-center justify-content-center w-100px h-100px">
                            <i className="bi bi-image fs-1"></i>
                        </div>}
                </div>
                <div className="text-primary cursor-pointer" onClick={() => setValue('brandingcustomization.logo', null)}>Delete image</div>
            </div>
        </div>
        <div className="mb-3">
            <div className="form-label">Fonts</div>
            <p>Select your typeface and font color</p>
            <div className="d-flex align-items-center gap-4 mb-3">
                <div className="min-w-200px">
                    <label className="form-label">Headings text</label>
                    <InputSelect
                        isClearable
                        options={fontOptions}
                        value={fontOptions.find((f: any) => f.value == watch('brandingcustomization.fonts.heading.typeface')) || null}
                        onChange={(e) => {
                            setValue('brandingcustomization.fonts.heading.typeface', e?.value ?? null)
                        }}
                    />
                </div>
                <div className="min-w-200px">
                    <label className="form-label" htmlFor="headingcolor">Text color</label>
                    <InputColor
                        value={watch('brandingcustomization.fonts.heading.color')}
                        onChange={val => setValue('brandingcustomization.fonts.heading.color', val)}
                    />
                </div>
            </div>
            <div className="d-flex align-items-center gap-4 mb-3">
                <div className="min-w-200px">
                    <label className="form-label">Body text</label>
                    <InputSelect
                        isClearable
                        options={fontOptions}
                        value={fontOptions.find((f: any) => f.value == watch('brandingcustomization.fonts.body.typeface')) || null}
                        onChange={(e) => {
                            setValue('brandingcustomization.fonts.body.typeface', e?.value ?? null)
                        }}
                    />
                </div>
                <div className="min-w-200px">
                    <label className="form-label" htmlFor="bodycolor">Secondary text color</label>
                    <InputColor
                        value={watch('brandingcustomization.fonts.body.color')}
                        onChange={val => setValue('brandingcustomization.fonts.body.color', val)}
                    />
                </div>
            </div>
            <button type="button" className="btn btn-sm btn-primary mt-4" onClick={() => setModalFonts(true)}>
                Upload custom fonts
            </button>
            <p>Allowed file types: .otf, .ttf, .worff</p>
        </div>
        <Modal show={modalFonts} onHide={() => setModalFonts(false)} centered size="lg">
            <Modal.Body className="pt-0">
                <ModalAddFonts
                    onSave={async (fonts: { name: string, file: File }[]) => {
                        try {
                            await Promise.all(fonts.map(async font => {
                                const formData = new FormData();
                                formData.append('uploadcode', 'FONTS')
                                formData.append('category', 'STORAGE')
                                formData.append('usedateinpath', 'false')
                                formData.append('file', font.file)
                                await ApiService.post(`utilities/uploadfiles`, formData)
                            }));
                            setModalFonts(false)
                            popupMessage({ icon: 'success', description: 'Your fonts were uploaded successfully!', autoClose: true })
                        } catch (error) {
                            ApiShowError(error)
                        }
                    }}
                    onClose={() => setModalFonts(false)}
                />
            </Modal.Body>
        </Modal>
        <div className="mt-6 d-flex flex-stack align-items-center">
            <TextFormByUser data={dataMerchant} />
            <FormSubmit
                type={isEdit}
                isSubmitting={isSubmitting}
                permission={permission}
                handleClose={() => navigate('/fnb/merchant-list')} />
        </div>
    </form>
}

export function InputColor(props: { value?: string, onChange: (color: string) => void }) {
    const colorRef = useRef<any>(null)
    const [color, setColor] = useState(props.value ?? '#000000')

    useEffect(() => {
        if (props.value && (/^#[0-9a-fA-F]{6}$/.test(props.value))) {
            setColor(props.value)
        }
    }, [props])

    const handleChange = useCallback(debounce((color: string) => {
        props.onChange(color)
    }, 300), [])

    return <div className="d-flex align-items-start position-relative">
        <input
            className="position-absolute start-0 top-0"
            style={{ visibility: 'hidden', width: 0, height: 0 }}
            ref={colorRef}
            type="color"
            value={color}
            onChange={(e) => {
                setColor(e.target.value)
                handleChange(e.target.value)
            }}
        />
        <div
            style={{ marginRight: '2px', background: color, width: '30px', height: '30px', borderRadius: '4px', cursor: 'pointer', border: color === '#ffffff' ? '1px solid #ccc' : 'none' }}
            onClick={() => colorRef.current?.click()}
        />
        <input
            className="form-control form-control-sm w-100px"
            value={color}
            onChange={(e) => {
                const isColor = /^#[0-9a-fA-F]{6}$/.test(e.target.value)
                if (isColor) setColor(e.target.value)
                props.onChange(e.target.value)
            }}
        />
    </div>
}

function ModalAddFonts({ onSave, onClose }: any) {
    const fileRef = useRef<HTMLInputElement>(null)
    const [fonts, setFonts] = useState<{ name: string, file: File }[]>([]);
    const [fileKey, setFileKey] = useState(Date.now())

    const handleDragOver = (e: any) => {
        e.preventDefault(); // Ngăn chặn sự kiện mặc định để file không mở ra trong trình duyệt
    };

    const handleDrop = async (e: any) => {
        e.preventDefault();
        const droppedFiles: File[] = Array.from(e.dataTransfer.files);
        await handleFontUpload(droppedFiles);
    };

    const handleFontUpload = async (files: File[]) => {
        const fontData = await Promise.all(
            files.map(async (file) => {
                const arrayBuffer = await file.arrayBuffer();
                // Tách tên file và loại bỏ phần đuôi để làm fontName
                const fontName = file.name.replace(/\.[^/.]+$/, "");

                // Tạo FontFace với fontName và add vào document
                const fontFace = new FontFace(fontName, arrayBuffer);
                await fontFace.load();
                document.fonts.add(fontFace);

                return {
                    name: fontName,
                    file,
                };
            })
        );
        setFonts(fontData);
    };

    return <>
        <input
            multiple
            key={fileKey}
            ref={fileRef}
            type="file"
            hidden
            accept=".otf, .ttf, .woff"
            onChange={e => {
                if (!e.target.files) return;
                const files = Array.from(e.target.files)
                handleFontUpload(files)
                setFileKey(Date.now())
            }}
        />
        <div className="d-flex align-items-center justify-content-between gap-2 py-4">
            <div className="fs-5 fw-bold">Upload custom fonts</div>
            <button className="btn btn-sm btn-icon" onClick={onClose}>
                <i className="bi bi-x fs-1 text-dark" />
            </button>
        </div>
        {fonts.length > 0 ?
            <>
                <div>
                    {fonts.map((font, i) => (
                        <div key={i} className="w-100 border-bottom py-4 bg-hover-light" onClick={() => setFonts(fonts.filter((_, j) => j != i))}>
                            <div className="fs-5 fw-bold">{font.name}</div>
                            <div className="py-4" style={{ fontFamily: font.name, fontSize: '24px' }}>
                                Đây là preview của font "{font.name}"
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end gap-2 mt-6">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Cancel</button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => onSave(fonts)}>Upload</button>
                </div>
            </>
            : <>
                <div
                    className="border border-dashed border-2 border-gray-400 rounded h-250px d-flex flex-column flex-center"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <i className="bi bi-files mb-2" style={{ fontSize: '36px' }} />
                    <p className="fs-8 mb-6">Max size 5MB, .ttf, .otf, .woff</p>
                    <div className="fw-bold">Drag and drop custom font</div>
                    <div className="my-2">or</div>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => fileRef.current?.click()}>Browse</button>
                </div>
            </>}
    </>
}

function loadImage(value: any) {
    if (value instanceof File) {
        return URL.createObjectURL(value)
    }
    return value
}

function isValidColor(color?: string) {
    return typeof color === 'string' && color.startsWith('#')
}

interface IPayment {
    paymentsetups: {
        mobilepayment: string | null // yes or no
        cash: string[] | null
        digitalreceiptdialog: string | null // yes or no
        thankyoudialog: string | null // yes or no
        cashreceiptoptions: string | null // yes or no
        minimumamount: number | null
        maximumamount: number | null
        acceptgiftcardpayment: string | null // yes or no
        otherpaymentoptions: {
            code: string
            name: string
            description: string
            function: string
            tendercoderef: number
        }[]
    },
    receiptsetups: {
        header: string | null
        footer: string | null
        logo: any
        largeordernumberinreceiptheader: string | null // show or hide
        guestcount: string | null // show or hide
        printpromoarea: string | null // yes or no
        promoarea: string | null
        promoimage: any
        closedcheckreceipt: string | null
    },
    // integrations: {
    //     momo: any
    //     bbot: any
    // }
}

function PaymentForm({ dataMerchant, permission, urlApi, refetch }: IDataTab) {
    const [tabActive, setTabActive] = useState<string>('Payment Options')
    const [integrationKey, setIntegrationKey] = useState(Date.now())

    return <div className="d-flex gap-6">
        <div className="w-275px">
            <div className="h-40px d-flex align-items-center">
                <div className="fs-5 fw-bold">Payment</div>
            </div>
            <hr className="mt-0" />
            <div className="d-flex flex-column gap-3">
                {['Payment Options', 'Receipt Setup', 'Integrations'].map((tab, i) => (
                    <button
                        key={i}
                        type="button"
                        className={clsx('p-3 py-2 d-flex align-items-center border border-secondary', tabActive == tab ? 'bg-primary text-white' : 'bg-white')}
                        onClick={() => {
                            setTabActive(tab)
                            setIntegrationKey(Date.now())
                        }}>
                        {tab}
                    </button>
                ))}
            </div>
        </div>
        {(tabActive == 'Payment Options') && <PaymentsetupsForm
            data={dataMerchant?.paymentsetups ?? {}}
            onSave={async (data: IPayment['paymentsetups']) => {
                let bodyData = { ...data }
                await ApiService.put(`${urlApi}/${dataMerchant?.id}`, { paymentsetups: bodyData })
                popupMessage({ icon: 'success', autoClose: true })
                refetch()
            }}
        />}
        {(tabActive == 'Receipt Setup') && <ReceiptSetupForm
            data={dataMerchant?.receiptsetups ?? {}}
            onSave={async (data: IPayment['receiptsetups']) => {
                let bodyData = { ...data }
                if (!!dataMerchant?.receiptsetups?.logo && (dataMerchant?.receiptsetups?.logo != data.logo)) {
                    const path = 'RECEIPT-SETUP' + String(dataMerchant?.receiptsetups?.logo).split('RECEIPT-SETUP').pop()
                    await ApiDeleteFile(path)
                }
                if (data.logo instanceof File) {
                    const files = await ApiUploadFiles(dataMerchant?.id as string, 'RECEIPT-SETUP', [data.logo])
                    if (files[0]) {
                        bodyData.logo = files[0].url
                    }
                }
                if (!!dataMerchant?.receiptsetups?.promoimage && (dataMerchant?.receiptsetups?.promoimage != data.promoimage)) {
                    const path = 'RECEIPT-SETUP-PROMO' + String(dataMerchant?.receiptsetups?.promoimage).split('RECEIPT-SETUP-PROMO').pop()
                    await ApiDeleteFile(path)
                }
                if (data.promoimage instanceof File) {
                    const files = await ApiUploadFiles(dataMerchant?.id as string, 'RECEIPT-SETUP-PROMO', [data.promoimage])
                    if (files[0]) {
                        bodyData.promoimage = files[0].url
                    }
                }
                try {
                    await ApiService.put(`${urlApi}/${dataMerchant?.id}`, { receiptsetups: bodyData })
                    popupMessage({ icon: 'success', autoClose: true })
                    refetch()
                } catch (error) {
                    ApiShowError(error)
                }
            }}
        />}
        {(tabActive == 'Integrations') && dataMerchant && dataMerchant.id
            && <IntegrationTab
                key={integrationKey}
                merchantData={dataMerchant}
            />}
    </div>
}

const integrationTypes = [{
    code: 'momo',
    title: 'MoMo',
    des: 'Trợ lý tài chính A.I giúp bạn quản lý tiền hiệu quả hơn. Quản lý chi tiêu thông minh: Tự động phân loại giao dịch qua MoMo,...',
    imageUrl: "https://developers.momo.vn/v3/img/logo2.svg"
}, {
    code: 'vnpay',
    title: 'VnPay',
    des: 'Cổng VNPAY kết nối doanh nghiệp với ngân hàng, hỗ trợ thanh toán qua thẻ, tài khoản, và QR Pay.',
    imageUrl: "https://stcd02206177151.cloud.edgevnpay.vn/assets/images/logo-icon/logo-primary.svg"
}]

function IntegrationTab({ merchantData }: { merchantData: ISMerchant }) {
    const queryClient = useQueryClient()
    const { data: integrationsData, isFetching } = useQuery([`fnb/integrations?merchantid=${merchantData?.id}`], () => ApiService.get(`fnb/integrations?merchantid=${merchantData?.id}`).then(res => res.data))
    const [integrationData, setIntegrationData] = useState<any>(null)

    function updateRowData(record: any) {
        let items = integrationsData ?? []
        const index = items.findIndex((f: any) => f.id == record.id)
        if (index > -1) {
            items[index] = record
        } else {
            items = [record, ...items]
        }
        queryClient.setQueryData([`fnb/integrations?merchantid=${merchantData?.id}`], items, { updatedAt: Date.now() })
    }

    async function handleChangeActive(checked: boolean, data: any) {
        try {
            const response = await ApiService.put(`/fnb/integrations/${data.id}`, { isactive: checked })
            updateRowData(response.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) {
            ApiShowError(error)
        }
    }

    if (isFetching) {
        return <div>Loading...</div>
    }

    return <div className="flex-fill">
        {!!integrationData
            ? <IntegrationForm
                merchantData={merchantData}
                handleChangeActive={(checked: boolean) => {
                    handleChangeActive(checked, integrationData)
                }}
                data={integrationData}
                onSave={async (data: any) => {
                    let bodyData = { ...data }
                    const CATEGORY = `INTEGRATION-ICON-${integrationData.servicecode}`.toUpperCase()
                    const prevIcon = integrationData?.custominfo?.icon
                    if (!!prevIcon && (prevIcon != data.custominfo?.icon)) {
                        const path = CATEGORY + String(prevIcon).split(CATEGORY).pop()
                        if (typeof prevIcon == 'string') {
                            try {
                                await ApiDeleteFile(path)
                            } catch (error) {

                            }
                        }
                    }
                    if (data.custominfo && data.custominfo.icon instanceof File) {
                        const files = await ApiUploadFiles(merchantData?.id as string, CATEGORY, [data.custominfo.icon])
                        if (files[0]) {
                            bodyData.custominfo = {
                                ...(bodyData.custominfo ?? {}),
                                icon: files[0].url
                            }
                        }
                    }
                    try {
                        const urlString = !!data.id ? `fnb/integrations/${data.id}` : 'fnb/integrations'
                        const method = !!data.id ? 'put' : 'post'
                        if (!data.encryptedsecretkey) {
                            delete bodyData.encryptedsecretkey
                        }
                        if (!data.ewalletcode) {
                            delete bodyData.ewalletcode
                        }
                        if (!data.publickey) {
                            delete bodyData.publickey
                        }
                        if (!data.accesskey) {
                            delete bodyData.accesskey
                        }
                        const response = await ApiService[method](urlString, { ...bodyData })
                        const tempData = {
                            "name": response.data.servicename,
                            "merchantcode": response.data.merchantcode,
                            "merchantname": bodyData.merchantname,
                            "allowinputamount": response.data.custominfo?.refundreturn || false,
                            "refundvoidline": response.data.custominfo?.refundreturn || false,
                            "refundvoidtransaction": response.data.custominfo?.refundreturn || false,
                            "refundreturn": response.data.custominfo?.refundreturn || false,
                            "icon": response.data.custominfo?.icon || null,
                            "isactive": response.data.isactive,
                            "customerno": response.data.custominfo?.customerno || null,
                        }
                        const res = await ApiService.get(`fnb/merchants/${merchantData?.id}`)
                        const _merchant = res.data
                        await ApiService.put(`fnb/merchants/${_merchant?.id}`, {
                            integrations: {
                                ...(_merchant.integrations || {}),
                                [response.data.servicecode]: tempData
                            }
                        })
                        updateRowData(response.data)
                        await popupMessage({ icon: 'success', autoClose: true })
                        setIntegrationData(null)
                    } catch (error) {
                        ApiShowError(error)
                    }
                }}
                onClose={() => {
                    setIntegrationData(null)
                }}
            />
            : <>
                <div className="fs-5 py-2">Integrations</div>
                <hr className="mt-0" />
                <div className="d-flex align-items-start flex-warp gap-4">
                    {integrationTypes.map(item => {
                        const _integration = integrationsData?.find((f: any) => f.servicecode == item.code)

                        return <div key={item.code} className="card card-body shadow-sm mw-200px">
                            <div
                                onClick={() => !!_integration && setIntegrationData(_integration)}
                                className={clsx("d-flex justify-content-center align-items-center w-100 h-100px", { "cursor-pointer": !!_integration })}
                            >
                                <img className="w-auto h-40px" src={item.imageUrl} alt="logo" />
                            </div>
                            <div className="mb-2 text-primary">Financial</div>
                            <div
                                onClick={() => !!_integration && setIntegrationData(_integration)}
                                className={clsx("fs-5 fw-bold mb-2", { "cursor-pointer": !!_integration })}
                            >
                                {item.title}
                            </div>
                            <div className="fs-7 min-h-100px">
                                {item.des}
                            </div>
                            <div className="d-flex justify-content-end gap-2 mt-4">
                                {!!_integration
                                    ? <>
                                        <label className="fw-bold me-2" htmlFor="momo-check">{_integration.isactive == true ? 'Active' : 'Deactive'}</label>
                                        <div className="form-check form-switch">
                                            <input
                                                id="momo-check"
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={_integration.isactive == true}
                                                onChange={(evt) => {
                                                    evt.preventDefault()
                                                    handleChangeActive(evt.target.checked, _integration)
                                                }}
                                            />
                                        </div>
                                    </>
                                    : <button type="button" className="btn btn-sm btn-primary" onClick={() => setIntegrationData({
                                        servicecode: item.code,
                                        merchantid: merchantData?.id,
                                        merchantcode: merchantData?.storecode
                                    })}>Add Now</button>}
                            </div>
                        </div>
                    })}
                </div>
            </>}
    </div>
}

function IntegrationForm({ merchantData, className, onSave, onClose, data, handleChangeActive }: any) {
    const typeData = useMemo(() => integrationTypes.find(f => f.code == data.servicecode), [data])
    const form = useForm<any>({
        resolver: yupResolver(object().shape({
            "servicecode": string().required(),
            "merchantid": string().required(),
            "merchantcode": string().required(),
            "servicename": string().required(),
            "accesskey": string().test('require', (val, ctx) => !ctx.parent['id'] ? !!val : true),
            "publickey": string().test('require', (val, ctx) => !ctx.parent['id'] ? !!val : true),
            "ewalletcode": string().test('require', (val, ctx) => !ctx.parent['id'] ? !!val : true),
            "encryptedsecretkey": string().test('require', (val, ctx) => !ctx.parent['id'] ? !!val : true)
        }))
    })

    useEffect(() => {
        if (data) {
            let temps: any = {}
            Object.keys(data).forEach(k => {
                if (['ewalletcode', 'publickey', 'accesskey'].includes(k)) return;
                temps[k] = data[k]
            })
            if (merchantData?.integrations && merchantData?.integrations[data.servicecode] && merchantData?.integrations[data.servicecode].merchantname) {
                temps['merchantname'] = merchantData?.integrations[data.servicecode].merchantname
            }
            form.reset({ ...temps })
        }
    }, [data])

    const imageRef = useRef<HTMLInputElement>(null)
    const [imageKey, setImageKey] = useState(Date.now())

    function handleImageChange(event: any) {
        const file = event.target.files[0]
        form.setValue('custominfo.icon', file)
        setImageKey(Date.now())
    }

    return <form onSubmit={form.handleSubmit(onSave, error => console.log(error))} className={className}>
        <div className="d-flex align-items-start gap-2 mb-6 mt-2">
            <div className="d-flex align-items-start gap-2">
                <img className="w-auto h-40px" src={typeData?.imageUrl} alt="logo" />
                <div>
                    <div className="fw-bold fs-5">{typeData?.title} Integration</div>
                    <div className="fs-7 text-gray-600">Financial</div>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <div className="fw-bold fs-5">General</div>
            <div className="d-flex justify-content-end ms-auto">
                <label className="fw-bold me-2" htmlFor="active-check">{form.watch('isactive') == true ? 'Active' : 'Deactive'}</label>
                <div className="form-check form-switch">
                    <input
                        id="active-check"
                        type="checkbox"
                        className="form-check-input"
                        checked={form.watch('isactive') == true}
                        onChange={(evt) => {
                            if (data?.id) {
                                form.setValue('isactive', evt.target.checked)
                                handleChangeActive(evt.target.checked)
                            } else {
                                form.setValue('isactive', evt.target.checked)
                            }
                        }}
                    />
                </div>
            </div>
        </div>
        <hr />
        <div className="row">
            <div className="col-md-9">
                <KTFormItem title="Tên hiển thị" labelWidth="150" isHeight>
                    <input className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['servicename']?.message })} {...form.register(`servicename`)} />
                </KTFormItem>
                <KTFormItem title="Icon" labelWidth="150" isHeight>
                    <div className="d-flex flex-column gap-3 align-items-start">
                        <input key={imageKey} ref={imageRef} style={{ display: 'none' }} type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleImageChange} />
                        <div className="border border-gray-400 p-1 shadow-sm">
                            {form.watch('custominfo.icon')
                                ? <img
                                    style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                                    src={loadImage(form.watch('custominfo.icon'))}
                                    alt="icon img"
                                    onClick={() => imageRef.current?.click()}
                                />
                                : <div
                                    onClick={() => imageRef.current?.click()}
                                    className="d-flex align-items-center justify-content-center w-100px h-100px cursor-pointer">
                                    Upload icon
                                </div>}
                        </div>
                        {!!form.watch('custominfo.icon') && <div className="text-primary cursor-pointer" onClick={() => form.setValue('custominfo.icon', null)}>Delete image</div>}
                    </div>
                </KTFormItem>
                <KTFormItem title="Merchant Name" labelWidth="150" isHeight>
                    <input className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['merchantname']?.message })} {...form.register(`merchantname`)} />
                </KTFormItem>
                <KTFormItem title="E-Wallet Code" labelWidth="150" isHeight>
                    <input
                        placeholder={data?.ewalletcode ?? undefined}
                        className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['ewalletcode']?.message })}
                        {...form.register(`ewalletcode`)} />
                </KTFormItem>
                <KTFormItem title="Access Key" labelWidth="150" isHeight>
                    <input
                        placeholder={data?.accesskey ?? undefined}
                        className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['accesskey']?.message })}
                        {...form.register(`accesskey`)} />
                </KTFormItem>
                <KTFormItem title="Secret Key" labelWidth="150" isHeight>
                    <input
                        placeholder={!!data?.id ? Array.from({ length: 100 }).map(i => '*').join("") : undefined}
                        className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['encryptedsecretkey']?.message })}
                        {...form.register(`encryptedsecretkey`)}
                    />
                </KTFormItem>
                <KTFormItem title="Public Key" labelWidth="150" isHeight>
                    <input
                        placeholder={data?.publickey ?? undefined}
                        className={clsx("form-control form-control-sm", { "form-error": form.formState.errors['publickey']?.message })}
                        {...form.register(`publickey`)} />
                </KTFormItem>

                <KTFormItem title="Store Slug" labelWidth="150" isHeight>
                    <input className="form-control form-control-sm" {...form.register(`custominfo.storeslug`)} />
                </KTFormItem>
                <KTFormItem title="Allow Input Amount" labelWidth="150">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" {...form.register(`custominfo.allowinputamount`)} />
                    </div>
                </KTFormItem>
                <KTFormItem title="Refund void line" labelWidth="150">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" {...form.register(`custominfo.refundvoidline`)} />
                    </div>
                </KTFormItem>
                <KTFormItem title="Refund void transaction" labelWidth="150">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" {...form.register(`custominfo.refundvoidtransaction`)} />
                    </div>
                </KTFormItem>
                <KTFormItem title="Refund return" labelWidth="150">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" {...form.register(`custominfo.refundreturn`)} />
                    </div>
                </KTFormItem>
                <KTFormItem title="Customer No." labelWidth="150" isHeight>
                    <input className="form-control form-control-sm" {...form.register(`custominfo.customerno`)} />
                </KTFormItem>
            </div>
            <div className="d-flex align-items-start justify-content-end gap-2">
                <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Cancel</button>
                <button type="submit" className="btn btn-sm btn-primary" disabled={form.formState.isSubmitting}>Submit</button>
            </div>
        </div>
    </form>
}

function HiddenInput({ form, data }: any) {

    function getPlaceholder(text: string) {
        if (text.length <= 5) return text; // Nếu chuỗi ngắn hơn hoặc bằng 5 ký tự, trả về nguyên chuỗi
        return text.slice(0, 5) + '*'.repeat(text.length - 5);
    }

    return <div className="w-100 position-relative">
        <input
            readOnly={form.watch(`ewalletcode`) === data?.ewalletcode}
            className="form-control form-control-sm"
            value={form.watch(`ewalletcode`) === data?.ewalletcode ? getPlaceholder(data?.ewalletcode) : form.watch(`ewalletcode`)}
            onChange={(e) => {
                form.setValue('ewalletcode', e.target.value)
            }}
        />
    </div>
}

function ReceiptSetupForm({ onSave, data }: any) {
    const form = useForm<IPayment['receiptsetups']>({})
    const imageRef = useRef<HTMLInputElement>(null)
    const [imageKey, setImageKey] = useState(Date.now())
    const imageRef2 = useRef<HTMLInputElement>(null)
    const [imageKey2, setImageKey2] = useState(Date.now())

    useEffect(() => {
        form.reset({ ...data })
    }, [data])

    return <form className="flex-fill" onSubmit={form.handleSubmit(onSave)}>
        <div className="h-40px d-flex align-items-center">
            <div className="fs-5 fw-bold">Receipt Setup</div>
            <div className="d-flex align-items-center gap-2 ms-auto">
                <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => form.reset({ ...data })}
                >
                    Reset
                </button>
                <button type="submit" className="btn btn-sm btn-primary">Save change</button>
            </div>
        </div>
        <hr className="mt-0" />
        <div className="bg-light-primary rounded p-3 mb-3">
            Based on your current printer setup, each line of the receipt header / footer can be no more than 42 characters.
        </div>
        <KTFormItem title="Header" labelWidth="150" isHeight>
            <CustomInput
                value={form.watch('header') ?? ''}
                onChange={(value) => form.setValue('header', value)}
            />
        </KTFormItem>
        <KTFormItem title="Footer" labelWidth="150" isHeight>
            <CustomInput
                value={form.watch('footer') ?? ''}
                onChange={(value) => form.setValue('footer', value)}
            />
        </KTFormItem>
        <KTFormItem title="Logo" labelWidth="150" isHeight>
            <div className="d-flex flex-column gap-3 align-items-start">
                <input
                    key={imageKey}
                    ref={imageRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={(event: any) => {
                        const file = event.target.files[0]
                        form.setValue('logo', file)
                        setImageKey(Date.now())
                    }}
                />
                <div className="border border-gray-400 p-1 shadow-sm">
                    {form.watch('logo')
                        ? <img
                            style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                            src={loadImage(form.watch('logo'))}
                            alt="image"
                            onClick={() => imageRef.current?.click()}
                        />
                        : <div
                            onClick={() => imageRef.current?.click()}
                            className="d-flex align-items-center justify-content-center w-100px h-100px cursor-pointer">
                            Upload logo
                        </div>}
                </div>
                {!!form.watch('logo') && <div className="text-primary cursor-pointer" onClick={() => form.setValue('logo', null)}>Delete image</div>}
            </div>
        </KTFormItem>
        <div className="fs-5 fw-bold mb-3">Advanced</div>
        <hr />
        {/* <KTFormItem title="Show Large Order Number in Receipt Header" labelWidth="150" isHeight>
            <div className="d-flex flex-column gap-3">
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'yes'} {...form.register('largeordernumberinreceiptheader')} />
                    <label className="form-check-label">Show Order Number</label>
                </div>
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'no'} {...form.register('largeordernumberinreceiptheader')} />
                    <label className="form-check-label">Don't show</label>
                </div>
            </div>
        </KTFormItem>
        <KTFormItem title="Show Guest Count" labelWidth="150" isHeight>
            <div className="d-flex flex-column gap-3">
                <div className="text-gray-600">Show Gust Count on Dine In Receipts</div>
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'yes'} {...form.register('guestcount')} />
                    <label className="form-check-label">Show Order Number</label>
                </div>
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'no'} {...form.register('guestcount')} />
                    <label className="form-check-label">Don't show</label>
                </div>
            </div>
        </KTFormItem> */}
        <KTFormItem title="Print Promo Area" labelWidth="150" isHeight>
            <div className="d-flex flex-column gap-3">
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'yes'} {...form.register('printpromoarea')} />
                    <label className="form-check-label">Yes</label>
                </div>
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'no'} {...form.register('printpromoarea', {
                        onChange(event) {
                            if (event.target.value == 'no') {
                                form.setValue('promoarea', null)
                                form.setValue('promoimage', null)
                            }
                        },
                    })} />
                    <label className="form-check-label">No</label>
                </div>
            </div>
        </KTFormItem>
        {form.watch('printpromoarea') == 'yes' && <>
            <KTFormItem title="Promo Area" labelWidth="150" isHeight>
                <CustomInput
                    value={form.watch('promoarea') ?? ''}
                    onChange={(value) => form.setValue('promoarea', value)}
                />
            </KTFormItem>
            <KTFormItem title="Promo Image" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3 align-items-start">
                    <input
                        key={imageKey2}
                        ref={imageRef2}
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        onChange={(event: any) => {
                            const file = event.target.files[0]
                            form.setValue('promoimage', file)
                            setImageKey2(Date.now())
                        }}
                    />
                    <div className="border border-gray-400 p-1 shadow-sm">
                        {form.watch('promoimage')
                            ? <img
                                style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                                src={loadImage(form.watch('promoimage'))}
                                alt="image"
                                onClick={() => imageRef2.current?.click()}
                            />
                            : <div
                                onClick={() => imageRef2.current?.click()}
                                className="d-flex align-items-center justify-content-center w-100px h-100px cursor-pointer">
                                Upload image
                            </div>}
                    </div>
                    {!!form.watch('promoimage') && <div
                        className="text-primary cursor-pointer"
                        onClick={() => form.setValue('promoimage', null)}>Delete image</div>}
                </div>
            </KTFormItem>
        </>}
        <KTFormItem title="Closed Check Receipt" labelWidth="150" isHeight>
            <div className="d-flex flex-column gap-3">
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'printPaymentReceipt'} {...form.register('closedcheckreceipt')} />
                    <label className="form-check-label">Print payment receipt</label>
                </div>
                <div className="form-check">
                    <input type="radio" className="form-check-input" value={'printInvoice'} {...form.register('closedcheckreceipt')} />
                    <label className="form-check-label">Print invoice</label>
                </div>
            </div>
        </KTFormItem>
    </form>
}

const paymentOptions = {
    "Cash": 1,
    "Credit Card": 2,
    "Voucher": 3,
    "Customer Account": 4,
    "Member Point": 5,
    "Member Point Ecoffee": 6,
    "Momo Second Display": 7,
    "Momo POS Scanner": 8,
    "Momo Print": 9,
    "VnPay Second Display": 10,
    "VnPay Print": 11,
    "Gift Card": 12,
    "Payoo": 13,
    "Urbox": 14,
    "Shopee Pay": 15,
    "UrboxN": 16,
    "Villship": 17,
    "GrabExpress COD": 18,
    "AhamoveCOD": 19,
    "GHN COD": 20,
    "Customer": 21
}

function PaymentsetupsForm({ onSave, data }: any) {
    const form = useForm<IPayment['paymentsetups']>({})
    const [modalPayment, setModalPayment] = useState(false)
    const [valueData, setValueData] = useState<any>(null)

    useEffect(() => {
        form.reset({ ...data })
    }, [data])

    return <>
        <form className="flex-fill" onSubmit={form.handleSubmit(onSave)}>
            <div className="h-40px d-flex align-items-center">
                <div className="fs-5 fw-bold">Payment Options</div>
                <div className="d-flex align-items-center gap-2 ms-auto">
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        onClick={() => form.reset({ ...data })}
                    >
                        Reset
                    </button>
                    <button type="submit" className="btn btn-sm btn-primary">Save change</button>
                </div>
            </div>
            <hr className="mt-0" />
            <div className="fs-5 mb-3">Mobile payments</div>
            <KTFormItem title="Allow guests to use mobile payments?" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3">
                    <div className="form-check">
                        <input type="radio" value={'yes'} className="form-check-input" {...form.register('mobilepayment')} />
                        <label className="form-check-label">Yes, provide guests with a contactless payment option using their own phone</label>
                    </div>
                    <div className="form-check">
                        <input type="radio" value={'no'} className="form-check-input" {...form.register('mobilepayment')} />
                        <label className="form-check-label">No, do not provide guests with a contactless payment option</label>
                    </div>
                </div>
            </KTFormItem>
            <div className="fs-5 mb-3">Cash</div>
            <KTFormItem title="Allow Cash Payments" labelWidth="150">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" value={'allow-cash-payments'} {...form.register('cash')} />
                </div>
            </KTFormItem>
            <KTFormItem title="Fast cash button" labelWidth="150">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" value={'fast-cash-button'} {...form.register('cash')} />
                </div>
            </KTFormItem>
            <KTFormItem title="Overtender Allowed" labelWidth="150">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" value={'overtender-allowed'} {...form.register('cash')} />
                </div>
            </KTFormItem>
            <KTFormItem title="Undertender Allowed" labelWidth="150">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" value={'undertender-allowed'} {...form.register('cash')} />
                </div>
            </KTFormItem>
            {/* <div className="fs-5 mb-3">Receipt Options</div>
            <KTFormItem title="Digital Receipt Dialog" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3">
                    <div className="form-check">
                        <input type="radio" value={'yes'} className="form-check-input" {...form.register('digitalreceiptdialog')} />
                        <label className="form-check-label">Yes, show the receipt dialog after each cash payment</label>
                    </div>
                    <div className="form-check">
                        <input type="radio" value={'no'} className="form-check-input" {...form.register('digitalreceiptdialog')} />
                        <label className="form-check-label">No, skip this dialog</label>
                    </div>
                </div>
            </KTFormItem>
            <KTFormItem title="Thank You Dialog" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3">
                    <div className="form-check">
                        <input type="radio" value={'yes'} className="form-check-input" {...form.register('thankyoudialog')} />
                        <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check">
                        <input type="radio" value={'no'} className="form-check-input" {...form.register('thankyoudialog')} />
                        <label className="form-check-label">No</label>
                    </div>
                </div>
            </KTFormItem>
            <KTFormItem title="Cash Receipt Options" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3">
                    <div className="form-check">
                        <input type="radio" value={'yes'} className="form-check-input" {...form.register('cashreceiptoptions')} />
                        <label className="form-check-label">Always Print Receipt</label>
                    </div>
                    <div className="form-check">
                        <input type="radio" value={'no'} className="form-check-input" {...form.register('cashreceiptoptions')} />
                        <label className="form-check-label">No Receipt</label>
                    </div>
                </div>
            </KTFormItem> */}
            <div className="fs-5 mb-3">Gift Card</div>
            <KTFormItem title="Minimum Amount" labelWidth="150" isHeight>
                <div className="d-flex align-items-center gap-2">
                    <InputPrice className="form-control form-control-sm w-150px" value={form.watch('minimumamount')} onChange={(value) => form.setValue('minimumamount', value)} />
                    <div className="text-gray-600">Dont't allow the sale of gift cards for less than this amount</div>
                </div>
            </KTFormItem>
            <KTFormItem title="Maximum Amount" labelWidth="150" isHeight>
                <div className="d-flex align-items-center gap-2">
                    <InputPrice className="form-control form-control-sm w-150px" value={form.watch('maximumamount')} onChange={(value) => form.setValue('maximumamount', value)} />
                    <div className="text-gray-600">Dont't allow the sale of gift cards for more than this amount. Max set to 10.000.000đ</div>
                </div>
            </KTFormItem>
            <KTFormItem title="Accept gift card payment for gift card purchases" labelWidth="150" isHeight>
                <div className="d-flex flex-column gap-3">
                    <div className="text-gray-600">Selecting "Yes" will allow customers to use a gift card to purchase a new gift card. "No" is selected by default.</div>
                    <div className="form-check">
                        <input type="radio" value={'yes'} className="form-check-input" {...form.register('acceptgiftcardpayment')} />
                        <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check">
                        <input type="radio" value={'no'} className="form-check-input" {...form.register('acceptgiftcardpayment')} />
                        <label className="form-check-label">No</label>
                    </div>
                </div>
            </KTFormItem>
            <div className="fs-5 mb-3">Other Payment Options</div>
            <table className="table table-row-bordered border border-gray-300">
                <thead>
                    <tr>
                        <th>Icon</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Function</th>
                        <th>Tender Code (Ref)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {form.watch('otherpaymentoptions')?.map((item: any, index: number) => {
                        return <tr key={index}>
                            <td className="p-0 ps-3">
                                <img
                                    src={item.ico}
                                    onError={evt => ''}
                                    className="min-w-30px w-30px h-30px object-fit-contain border"
                                />
                            </td>
                            <td>{item.code}</td>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.function}</td>
                            <td>{item.tendercode}</td>
                            <td className="d-flex justify-content-end gap-2">
                                <RowIcon
                                    action="modify"
                                    onClick={() => {
                                        setValueData({ ...item, index })
                                        setModalPayment(true)
                                    }}
                                />
                                <RowIcon
                                    notQuestion
                                    action="delete"
                                    onClick={() => {
                                        form.setValue('otherpaymentoptions', (form.watch('otherpaymentoptions') ?? []).filter(f => f.code != item.code))
                                    }}
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <div className="text-primary cursor-pointer" onClick={() => {
                setModalPayment(true)
                setValueData({ index: -1 })
            }}>
                + Add Other Payment Option
            </div>
        </form>
        <Modal show={modalPayment} onHide={() => setModalPayment(false)} centered>
            <Modal.Header closeButton className="py-4">
                <Modal.Title>{valueData?.index >= 0 ? 'Edit' : 'Add'} Other Payment Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalAddOtherPaymentOptions
                    rowDatas={form.watch('otherpaymentoptions') ?? []}
                    valueData={valueData}
                    onSave={async (data: any) => {
                        setModalPayment(false)
                        let temps = form.watch('otherpaymentoptions') ?? []
                        if (!!data.ico) {
                            data.ico = await toBase64(data.ico as File)
                        }
                        if (typeof data.index == 'number' && data.index >= 0) {
                            temps[data.index] = data
                        } else {
                            temps = [...temps, data]
                        }
                        form.setValue('otherpaymentoptions', temps)
                    }}
                    onClose={() => setModalPayment(false)}
                />
            </Modal.Body>
        </Modal>
    </>
}

const CustomInput = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const cursorPosition = e.target.selectionStart;

        onChange(e.target.value);

        // Đặt lại vị trí con trỏ để không bị nhảy về cuối
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.selectionStart = cursorPosition;
                textareaRef.current.selectionEnd = cursorPosition;
            }
        }, 0);
    };

    function countCharactersonLines() {
        // Tách từng dòng bằng dấu xuống dòng
        const lines = (value ?? '').split('\n');

        // Đếm số ký tự của mỗi dòng
        return <>
            {lines.map((line, index) => <span style={{ color: line.length > 42 ? 'red' : undefined }}>
                {index + 1}.{line.length}{lines.length == index + 1 ? '' : ', '}
            </span>)}
        </>
    }

    return (
        <div className="w-100">
            <div
                style={{ padding: '0 12px' }}
                className="text-center d-flex align-items-center justify-content-center py-1"
            >
                <span className="flex-fill position-relative" style={{ borderBottom: '1.25px solid #3f4254' }}>
                    <div className="position-absolute" style={{ left: '-5px', transform: 'translateY(-41.2%)' }}>
                        <i className="bi bi-chevron-left text-gray-800 fs-5"></i>
                    </div>
                </span>
                <span className="text-gray-600 mx-8">42 characters</span>
                <span className="flex-fill position-relative" style={{ borderBottom: '1.25px solid #3f4254' }}>
                    <div className="position-absolute" style={{ right: '-5px', transform: 'translateY(-41.2%)' }}>
                        <i className="bi bi-chevron-right text-gray-800 fs-5"></i>
                    </div>
                </span>
            </div>
            <textarea
                ref={textareaRef}
                rows={4}
                className="form-control form-control-sm text-center"
                value={value}
                onChange={handleChange}
            />
            <div>{countCharactersonLines()}</div>
        </div>
    );
};

const ModalAddOtherPaymentOptions = ({ rowDatas, valueData, onSave, onClose }: any) => {
    const form = useForm({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            name: yup.string().required(),
            description: yup.string().required(),
            function: yup.string().required(),
            tendercode: yup.string().required(),
        }))
    });

    const optionFuncs = [
        { value: 'Normal', label: 'Normal' },
        { value: 'Voucher', label: 'Voucher' },
        { value: 'Customer', label: 'Customer' }
    ]

    const [icoKey, setIcoKey] = useState(Date.now())
    const icoRef = useRef<HTMLInputElement | null>(null)

    const codes = rowDatas.map((m: any) => m.code)
    const options = Object.keys(paymentOptions)
        .filter(f => !codes.includes(f) || (valueData && valueData.code && valueData.code == f))
        .map(m => ({ value: m, label: m, tendercode: (paymentOptions as any)[m] as number }))

    useEffect(() => {
        if (valueData) {
            form.reset({ ...valueData })
        }
    }, [valueData])

    return <form onSubmit={form.handleSubmit(onSave)}>
        <div className="mb-3">
            <label htmlFor="Ico">Icon</label>
            <div className="d-flex flex-column gap-3 align-items-start">
                <input
                    key={icoKey}
                    ref={icoRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={(event: any) => {
                        const file = event.target.files[0]
                        form.setValue('ico', file)
                        setIcoKey(Date.now())
                    }}
                />
                <div className="border border-gray-400 p-1 shadow-sm">
                    {form.watch('ico')
                        ? <img
                            className="min-w-30px w-30px h-30px object-fit-contain border"
                            src={loadImage(form.watch('ico'))}
                            onClick={() => icoRef.current?.click()}
                            alt="iconpayment"
                        />
                        : <div
                            onClick={() => icoRef.current?.click()}
                            className="d-flex align-items-center justify-content-center min-w-30px w-30px h-30px cursor-pointer">
                        </div>}
                </div>
                {!!form.watch('ico') && <div
                    className="text-primary cursor-pointer"
                    onClick={() => form.setValue('ico', null)}>Delete icon</div>}
            </div>
        </div>
        <div className="mb-3">
            <label htmlFor="Code">Code</label>
            <InputSelect
                options={options}
                value={options.find(f => f.value == form.watch('code')) ?? null}
                onChange={op => {
                    form.setValue('code', op?.value ?? null)
                    form.setValue('tendercode', op?.tendercode ?? null)
                }}
                className={clsx('w-100', { "form-error": form.formState.errors.code })}
                size="sm"
            />
        </div>
        <div className="mb-3">
            <label htmlFor="Name">Name</label>
            <input className={clsx("form-control form-control-sm", { "form-error": form.formState.errors.name })} id="Name" {...form.register("name")} />
        </div>
        <div className="mb-3">
            <label htmlFor="Description">Description</label>
            <textarea className={clsx("form-control form-control-sm", { "form-error": form.formState.errors.description })} id="Description" {...form.register("description")} />
        </div>
        <div className="mb-3">
            <label htmlFor="Function">Function</label>
            <InputSelect
                options={optionFuncs}
                value={optionFuncs.find(f => f.value == form.watch('function')) ?? null}
                onChange={option => {
                    form.setValue('function', option.value ?? null);
                }}
                className={clsx("w-100", { "form-error": form.formState.errors.function })}
            />
        </div>
        <div className="mb-4">
            <label htmlFor="ref" className="form-label">Tender Code (Ref)</label>
            <input disabled className={clsx("form-control form-control-sm", { "form-error": form.formState.errors.tendercode })} id="ref" {...form.register('tendercode')} />
        </div>
        <div className="d-flex justify-content-end gap-2">
            <button type="button" className="btn btn-sm btn-secondary" onClick={onClose}>Cancel</button>
            <button type="submit" className="btn btn-sm btn-primary" disabled={form.formState.isSubmitting}>Save</button>
        </div>
    </form>

}

function SectionAndTableForm({ dataMerchant, permission }: IDataTab) {
    const intl = useIntl()
    const urlquery = dataMerchant?.id ? `?merchantid=${dataMerchant.id}` : undefined
    const { data: dataAllTables, isSuccess: isTables, refetch: refetchAllTables } = apiFnb.useHospDiningTablesByQuery(urlquery)
    const { data: dataSections, isSuccess: isSections, isFetching: loadSections } = apiFnb.useHospDiningAreaSectionsByQuery(urlquery)
    const [sections, setSections] = useState<IHospDiningAreaSection[]>([])
    const [checkedSection, setCheckedSection] = useState<IHospDiningAreaSection | undefined>()

    useEffect(() => {
        if (!loadSections && isSections) {
            const array = sortByNumber(dataSections, 'seq')
            setSections(array)
            setCheckedSection(Array.isArray(array) && array[0] ? array[0] : undefined)
        }
    }, [loadSections])

    const { watch, register, handleSubmit, reset, setValue, formState: { errors, isSubmitting } } = useForm<IHospDiningAreaSection>({
        resolver: yupResolver(yup.object().shape({
            sectionname: yup.string().required(),
            servicetype: yup.string().required(),
        }))
    })
    const [valueSection, setValueSection] = useState<IHospDiningAreaSection | undefined>()
    const { isEdit, valueid } = getValueId(valueSection?.id)

    const addSection = () => {
        const newSection = { seq: 0, merchantid: dataMerchant?.id as any, sectioncode: null, sectionname: null, salestype: null, servicetype: 'Not Specified' }
        setValueSection(newSection)
        reset(newSection)
    }

    const onReorder = async (items: any[]) => {
        let _items = items.map((item, index: number) => ({ ...item, seq: index + 1 }))
        try {
            await ApiService.post('fnb/hospdiningareasections/upsertmulti', _items)
            setSections(_items)
        } catch (error) {
            ApiShowError(error)
        }
    }

    useEffect(() => {
        reset(valueSection)
    }, [valueSection])

    const serverTypeOptions = [
        { value: 'Not Specified', label: 'Not Specified' },
        { value: 'Dining Table Service', label: 'Dining Table Service' },
        { value: 'Self Service', label: 'Self Service' },
        { value: 'Delivery', label: 'Delivery' }
    ]

    return <>
        <div className="d-flex gap-6">
            <div className="w-275px">
                <div className="d-flex flex-stack">
                    <div className="fs-5 p-2">
                        <FormattedMessage id="text.sections" />
                    </div>
                    <KTButton
                        permission={permission}
                        action="itemnew"
                        onClick={addSection}
                        titleBtn={intl.formatMessage({ id: "text.section.add" })}
                        classBtn='btn btn-link btn-sm py-0'
                    />
                </div>
                <hr className="mt-0" />
                <Reorder.Group as="div" axis="y" values={sections} onReorder={onReorder} className="d-flex flex-column gap-2">
                    {sections.map((item: any, index: number) => {
                        const isActive = item.id == checkedSection?.id

                        return <Reorder.Item as="div" key={item.id} value={item}>
                            <div
                                className={clsx("ps-3  border border-secondary d-row-hover cursor-pointer", isActive ? "bg-primary text-white" : "bg-white text-dark")}
                                onClick={() => {
                                    setCheckedSection(item)
                                    reset(item)
                                }}>
                                <div className="d-flex align-items-center gap-2">
                                    <span>{item.sectionname}</span>
                                    <div
                                        className="btn btn-icon btn-sm ms-auto d-cell-hover"
                                        onClick={() => setValueSection(item)}
                                    >
                                        <KTSVG path={'/media/icons/custom/svgexport.svg'} className={clsx(`svg-icon-4`, isActive ? 'svg-icon-white' : 'svg-icon-primary')} />
                                    </div>
                                </div>
                            </div>
                        </Reorder.Item>
                    })}
                </Reorder.Group>
            </div>
            <div className="flex-fill">
                {isTables && <CardTablesBySection permission={permission} valueSection={checkedSection} dataAllTables={dataAllTables} refetchAllTables={refetchAllTables} />}
            </div>
        </div>
        <Modal show={valueSection != undefined} onHide={() => setValueSection(undefined)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{valueSection?.id ? <FormattedMessage id="text.edit" /> : <FormattedMessage id="text.add" />} <FormattedMessage id="text.section" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(async (data: any) => {
                    try {
                        const method = isEdit ? 'put' : 'post'
                        const result = await ApiService[method](`/fnb/hospdiningareasections${isEdit ? `/${valueid}` : ''}`, changeDataModify(data))
                        // if (valueSection?.salestype != data.salestype) {
                        //     const tables = await ApiService.get(`/fnb/hospdiningtables?sectionid=${valueSection?.id}&merchantid=${dataMerchant?.id}`)
                        //     const _tables = tables.data.map((t: any) => ({ ...t, salestype: data.salestype ?? null }))
                        //     await ApiService.post(`/fnb/hospdiningtables/upsertmulti`, _tables)
                        // }
                        popupMessage({ icon: 'success', autoClose: true })
                        setSections(isEdit ? sections.map(m => m.id == valueid ? data : m) : [result.data, ...sections])
                        if (!isEdit) setCheckedSection(result.data)
                        setValueSection(undefined)
                    } catch (error) {
                        ApiShowError(error)
                    }
                })}>
                    {/* <KTFormItem title="SEQ" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.seq })} {...register('seq')} />
                    </KTFormItem> */}
                    <KTFormItem title="text.section.name" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.sectionname })} {...register('sectionname')} />
                    </KTFormItem>
                    <KTFormItem title="Sales type" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.salestype })} {...register('salestype')} />
                    </KTFormItem>
                    <KTFormItem title="Service type" isBLockLabel>
                        <InputSelect
                            className={clsx("w-100", { 'form-error': errors.servicetype })}
                            options={serverTypeOptions}
                            value={serverTypeOptions.find((f: any) => f.value == watch('servicetype')) ?? null}
                            onChange={option => {
                                setValue('servicetype', option?.value ?? null)
                            }}
                        />
                    </KTFormItem>
                    <TextFormByUser data={watch()} />
                    <div className="mt-6 d-flex flex-end gap-2">
                        {!!valueSection?.id && <KTButton permission={permission} disabled={!valueSection?.id} action='itemdelete' titleBtn="Delete" onClick={async () => {
                            setValueSection(undefined)
                            const question = await popupDelete()
                            const tables = dataAllTables.filter((f: any) => f.sectionid == valueSection?.id)
                            if (tables.length) return popupMessage({ icon: "error", description: intl.formatMessage({ id: "message.delete.dependencies-exist" }) })
                            if (question && valueSection?.id) {
                                try {
                                    await ApiService.delete(`fnb/hospdiningareasections/${valueSection.id}`)
                                    setSections(sections.filter(f => f.id != valueSection.id))
                                    popupMessage({ icon: 'success', autoClose: true })
                                } catch (error) {
                                    ApiShowError(error)
                                }
                            }
                        }} />}
                        <FormSubmit
                            hideCloseButton
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            permission={permission}
                            handleClose={() => setValueSection(undefined)} />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
}

interface ICardTablesBySection {
    valueSection?: IHospDiningAreaSection
    dataAllTables: IHospDiningTable[]
    refetchAllTables: any,
    permission?: string
}

function CardTablesBySection({ valueSection, dataAllTables, refetchAllTables, permission }: ICardTablesBySection) {
    const intl = useIntl()
    const [tables, setTables] = useState<IHospDiningTable[]>([])
    const [valueTable, setValueTable] = useState<IHospDiningTable | undefined>()

    useEffect(() => {
        if (valueSection?.id) {
            setTables(sortByNumber(dataAllTables.filter(f => valueSection.id == f.sectionid), 'tableno'))
        } else {
            setTables([])
        }
    }, [valueSection?.id])

    const columnsTable: Array<Column<IHospDiningTable>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'tableno',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueTable(props.data[props.row.index])}
            data={props.data[props.row.index].tableno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'tablename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tablename} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.seat-capacity' />,
        accessor: 'seatcapacity',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].seatcapacity} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Sales type' />,
        accessor: 'salestype',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].salestype} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' className="w-80px" />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" onClick={() => setValueTable(props.data[props.row.index])} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`fnb/hospdiningtables/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTables(pre => pre.filter(f => f.id != id))
                refetchAllTables()
            }} />
        </div>
    }]

    return <>
        <div className="d-flex flex-stack">
            <span className="fs-5 p-2"><FormattedMessage id="text.dining-tables" /></span>
            <KTButton
                permission={permission}
                action="itemnew"
                classBtn='btn btn-link btn-sm py-0'
                titleBtn={intl.formatMessage({ id: "text.dining-table.add" })}
                disabled={!valueSection}
                onClick={() => {
                    if (valueSection) {
                        const { merchantid, id, sectioncode } = valueSection
                        merchantid && setValueTable({
                            merchantid: merchantid,
                            sectionid: id || null,
                            sectioncode: sectioncode,
                            tableno: null,
                            tablename: null,
                            seatcapacity: null,
                            mincapacity: null,
                            maxcapacity: null,
                            qrcodeordertype: null,
                            qrcodetype: null,
                            expirationtime: null,
                            qrexpireat: null,
                            qrcodethumb: null,
                            qrcode: null,
                            active: false,
                            diningtablestatus: 'free'
                        })
                    }
                }}
            />
        </div>
        <hr className="mt-0" />
        <KTTable columns={columnsTable} data={tables} pagination />
        {valueTable && <ModalDiningTable key={'ModalDiningTable'} {...{
            valueSection,
            permission,
            dataAllTables,
            valueData: valueTable,
            handleClose: function () {
                setValueTable(undefined)
            },
            handleSave: async function (values: IHospDiningTable, isEdit: boolean) {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`/fnb/hospdiningtables${isEdit ? `/${values.id}` : ''}`, changeDataModify(values))
                    popupMessage({ icon: 'success', autoClose: true })
                    setTables(isEdit ? tables.map(m => m.id == values.id ? values : m) : [result.data, ...tables])
                    refetchAllTables()
                } catch (error) {
                    ApiShowError(error)
                }
                setValueTable(undefined)
            }
        }} />}
    </>
}

function ModalDiningTable({ valueSection, valueData, handleClose, handleSave, dataAllTables, permission }: any) {
    const { isEdit, valueid } = getValueId(valueData?.id)
    const { watch, register, control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<IHospDiningTable>({
        resolver: yupResolver(yup.object().shape({
            tableno: yup.string().test('unique', (val, ctx) => {
                if (val && val != valueData?.tableno && dataAllTables.map((m: any) => m.tableno).includes(val)) return ctx.createError()
                return true
            }).required(),
            tablename: yup.string().required(),
            seatcapacity: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            diningtablestatus: yup.string().required(),
        }))
    })

    useEffect(() => {
        if (valueData) reset({ ...valueData, salestype: valueSection?.salestype ?? null })
    }, [valueData])

    // Free, Seated, Occupied, To Be Cleaned
    const optionStatus: any[] = [
        { label: 'Free', value: 'free' },
        { label: 'Seated', value: 'seated' },
        { label: 'Occupied', value: 'occupied' },
        { label: 'To Be Cleaned', value: 'to-be-cleaned' },
    ]

    return <Modal show={valueData != undefined} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{valueData?.id ? <FormattedMessage id="text.edit" /> : <FormattedMessage id="text.add" />} <FormattedMessage id="text.dining-table" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit((data) => handleSave(data, isEdit))}>
                <KTFormItem title="text.section.name">
                    <span>{valueSection.sectionname}</span>
                </KTFormItem>
                <KTFormItem title="Sales type">
                    <input disabled className={clsx("form-control form-control-sm", { "form-error": errors.salestype })} {...register("salestype")} />
                </KTFormItem>
                <KTFormItem title="text.id">
                    <input disabled={!!valueData?.id} className={clsx("form-control form-control-sm", { "form-error": errors.tableno })} {...register("tableno")} />
                </KTFormItem>
                <KTFormItem title="text.name">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.tablename })} {...register("tablename")} />
                </KTFormItem>
                <KTFormItem title="text.status">
                    <Controller
                        control={control}
                        name="diningtablestatus"
                        render={({ field: { value, onChange } }) => <InputSelect
                            className={clsx("w-100", { "form-error": errors.diningtablestatus })}
                            options={optionStatus}
                            value={optionStatus.find(f => f.value == value)}
                            onChange={(e) => {
                                onChange(e.value)
                            }}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="text.seat-capacity">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.seatcapacity })} {...register("seatcapacity")} type="number" />
                </KTFormItem>
                <KTFormItem title="text.active">
                    <label className="form-check form-check-sm form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register('active')} />
                    </label>
                </KTFormItem>
                <div className="mt-6 d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}

function StaffForm({ dataMerchant, permission }: IDataTab) {
    const urlquery = dataMerchant?.id ? `?merchantid=${dataMerchant?.id}` : undefined
    const { data: dataHospStaffs, isSuccess: isHospStaffs, isFetching: loadHospStaffs } = apiFnb.useHospStaffsByQuery(urlquery)
    const [dataTable, setDataTable] = useState<IHospStaff[]>([])
    const [valueData, setValueData] = useState<IHospStaff | undefined>()

    useEffect(() => {
        if (!loadHospStaffs && isHospStaffs) {
            setDataTable(dataHospStaffs)
        } else {
            setDataTable([])
        }
    }, [loadHospStaffs])

    const columnsTable: Array<Column<IHospStaff>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='ID' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData({ ...props.data[props.row.index], merchantkey: dataMerchant?.merchantkey })}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Fullname' />,
        accessor: 'fullname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].fullname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Name on Receipt' />,
        accessor: 'nameonreceipt',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].nameonreceipt} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Store No' />,
        accessor: 'merchantkey',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].merchantkey} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Permission Group' />,
        accessor: 'staffpermissiongroupcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].staffpermissiongroupcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Hành động' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon
                permission={permission}
                action="modify"
                onClick={() => setValueData({ ...props.data[props.row.index], merchantkey: dataMerchant?.merchantkey })}
            />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`fnb/staffs/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setDataTable(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<IHospStaff | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IHospStaff[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks: boolean[] = []
            return checks.every(val => val == true)
        })
    }

    const { data: staffPermissionGroups } = useQuery([`settings/staffpermissiongroups`], () => ApiService.get(`settings/staffpermissiongroups`).then(res => res.data))
    const optionPermissionGroup: any[] = formatOptions(staffPermissionGroups || [], ['id', 'code'])

    return <>
        <KTTable
            isLoading={loadHospStaffs}
            toolbar={<>
                <FilterDropdown
                    handleSubmit={handleSubmit}
                    onClose={() => {
                        setFilter(undefined)
                        reset({})
                    }}
                    onSubmit={(data) => setFilter(data)}>
                    <KTFormItem title='text.active' labelWidth="auto">
                        <label className="form-check form-switch form-check-custom">
                            <input className="form-check-input" type="checkbox" {...register("active")} />
                        </label>
                    </KTFormItem>
                </FilterDropdown>
                <KTButton permission={permission} action="new" disabled={!dataMerchant?.id} onClick={() => {
                    setValueData({
                        merchantid: dataMerchant?.id as string,
                        merchantkey: dataMerchant?.merchantkey || '',
                        code: null,
                        fullname: null,
                        nameonreceipt: null,
                        blocked: false,
                        datetobeblocked: null,
                        lastzreport: null,
                        loggedintopos: null,
                    })
                }} />
            </>}
            columns={columnsTable}
            data={onFilter(dataTable)}
            pagination
            search
        />
        {valueData && <ModalHospStaff key={'ModalHospStaff'} {...{
            dataMerchant: dataMerchant,
            optionPermissionGroup,
            permission,
            valueData,
            handleClose: function () { setValueData(undefined) },
            handleSave: async function (values: IHospStaff, isEdit: boolean) {
                try {
                    if (!values.password || values.password == "") {
                        delete values.password
                    }
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`/fnb/staffs${isEdit ? `/${values.id}` : ''}`, changeDataModify(values))
                    popupMessage({ icon: 'success', autoClose: true })
                    setDataTable(isEdit ? dataTable.map(m => m.id == values.id ? values : m) : [result.data, ...dataTable])
                    setValueData(undefined)
                } catch (error) { ApiShowError(error) }
            }
        }} />}
    </>
}

function ModalHospStaff({ dataMerchant, optionPermissionGroup, valueData, handleClose, handleSave, permission }: any) {
    const { isEdit, valueid } = getValueId(valueData?.id)
    const { watch, register, control, setValue, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<IHospStaff>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().required(),
            fullname: yup.string().required(),
            nameonreceipt: yup.string().required(),
            password: yup.string().test('add', (val, ctx) => {
                return isEdit ? true : !!val
            })
        }))
    })

    useEffect(() => {
        if (valueData) {
            reset({ ...valueData, password: '' })
        }
    }, [valueData])

    const optionMerchantKey = dataMerchant?.merchantkey ? [{ value: dataMerchant?.merchantkey, label: dataMerchant?.merchantkey }] : []

    return <Modal show={valueData != undefined} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                {valueData?.id
                    ? <FormattedMessage id="text.edit" />
                    : <FormattedMessage id="text.add" />} <FormattedMessage id="text.staff" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form
                onSubmit={handleSubmit(
                    (data) => handleSave(data, isEdit),
                    (error: any) => { console.log(error) }
                )}
            >
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <KTFormItem title="text.id">
                            <input className={clsx("form-control form-control-sm", { "form-error": errors.code })} {...register("code")} />
                        </KTFormItem>
                        <KTFormItem title="text.name">
                            <input className={clsx("form-control form-control-sm", { "form-error": errors.fullname })} {...register("fullname")} />
                        </KTFormItem>
                        <KTFormItem title="text.name-on-receipt">
                            <input className={clsx("form-control form-control-sm", { "form-error": errors.nameonreceipt })} {...register("nameonreceipt")} />
                        </KTFormItem>
                        <KTFormItem title="Merchant Key">
                            <InputSelect
                                isClearable
                                options={optionMerchantKey}
                                value={optionMerchantKey.find(f => f.value == watch('merchantkey')) ?? null}
                                onChange={(option) => {
                                    setValue('merchantkey', option?.value ?? null)
                                }}
                            />
                        </KTFormItem>
                    </div>
                    <div className="col-12 col-lg-6">
                        <KTFormItem title="Logged into POS">
                            <input
                                className={clsx("form-control form-control-sm", { "form-error": errors.loggedintopos })}
                                {...register("loggedintopos")}
                            />
                        </KTFormItem>
                        <KTFormItem title="Last ZReport">
                            <input
                                className={clsx("form-control form-control-sm", { "form-error": errors.lastzreport })}
                                {...register("lastzreport")}
                            />
                        </KTFormItem>
                        <KTFormItem title="Permission group">
                            <Controller
                                name="staffpermissiongroupid"
                                control={control}
                                render={({ field: { value, onChange } }) => <InputSelect
                                    options={optionPermissionGroup}
                                    value={optionPermissionGroup.find((f: any) => f.value == value) || null}
                                    onChange={(e) => {
                                        if (!e) return onChange(null)
                                        onChange(e.value)
                                        setValue('staffpermissiongroupcode', e.label)
                                    }
                                    }
                                />} />
                        </KTFormItem>
                        <KTFormItem title="Blocked">
                            <label className="form-check form-check-sm form-check-custom">
                                <input className="form-check-input" type="checkbox" {...register('blocked', {
                                    onChange(event) {
                                        if (event.target.checked) {
                                            setValue('datetobeblocked', new Date().toISOString())
                                        } else {
                                            setValue('datetobeblocked', null)
                                        }
                                    },
                                })} />
                            </label>
                        </KTFormItem>
                    </div>
                    <hr />
                    <div className="card-header px-0 pt-0 border-bottom-0">
                        <div className="card-title"><FormattedMessage id="text.personal" /> </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <ExtendAddressValue {...{
                                register,
                                control,
                                errors,
                                setValue,
                                watch
                            }} />
                            <KTFormItem title='text.address2'>
                                <input className={`form-control form-control-sm ${errors.address2 && 'form-error'}`} {...register('address2')} />
                            </KTFormItem>
                            <KTFormItem title='text.home-phone-no'>
                                <input className={`form-control form-control-sm ${errors.homephoneno && 'form-error'}`} {...register('homephoneno')} />
                            </KTFormItem>
                        </div>
                        <div className="col-12 col-lg-6">
                            <KTFormItem title='text.work-phone-no'>
                                <input className={`form-control form-control-sm ${errors.workphoneno && 'form-error'}`} {...register('workphoneno')} />
                            </KTFormItem>
                            <KTFormItem title='text.password'>
                                <input placeholder={'******'} className={`form-control form-control-sm ${errors.password && 'form-error'}`} {...register('password')} />
                            </KTFormItem>
                            <KTFormItem title='text.change-password'>
                                <label className="form-check form-check-sm form-check-custom">
                                    <input className="form-check-input" type="checkbox" {...register('ispasswordchange')} />
                                </label>
                            </KTFormItem>
                            <KTFormItem title='text.payroll-no'>
                                <input className={`form-control form-control-sm ${errors.payrollno && 'form-error'}`} {...register('payrollno')} />
                            </KTFormItem>
                            <KTFormItem title='text.hourly-rate'>
                                <input className={`form-control form-control-sm ${errors.hourlyrate && 'form-error'}`} {...register('hourlyrate')} />
                            </KTFormItem>
                        </div>
                    </div>
                </div>
                <div className="mt-6 d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}

const optionQrType = [
    { value: "static", label: "Static" },
    { value: "dynamic", label: "Dynamic" },
]

function printImage(url: string) {
    const newWindow = window.open("", "_blank");
    if (!newWindow) return popupMessage({ icon: 'error', description: 'Please allow popups for this website' })
    newWindow.document.write(`
        <html>
        <head>
            <title>Print Image</title>
        </head>
        <body style="text-align: center;">
            <img src="${url}" style="max-width: 100%; height: auto;">
            <script>
                window.onload = function() {
                    window.print();
                    setTimeout(() => window.close(), 500);
                };
            </script>
        </body>
        </html>
    `);
    newWindow.document.close();
}

function QRCodeForm({ dataMerchant, permission }: IDataTab) {
    const { data: storeTierData, isSuccess: isStoreTier } = apiAd.useStoreTiersByID<IConfigStoreTier>(dataMerchant?.storetierid as string)
    const optionOrderType = isStoreTier ? formatOptions(storeTierData?.ordertypes, ['code', 'name']) : []
    const intl = useIntl()
    const urlquery = dataMerchant?.id ? `?merchantid=${dataMerchant.id}&select=qrcodethumb` : undefined
    const { data: dataTables, isSuccess: isTables, isFetching: loadTables } = apiFnb.useHospDiningTablesByQuery(urlquery)
    const [tables, setTables] = useState<IHospDiningTable[]>([])
    const [valueTable, setValueTable] = useState<IHospDiningTable | undefined>()
    const optionTables = isTables ? formatOptions(dataTables, ['tableno', 'tablename']) : []
    const [previewImage, setPreviewImage] = useState<IHospDiningTable | undefined>()

    useEffect(() => {
        if (!loadTables && isTables) {
            setTables(sortWithAny(dataTables.filter((f: any) => f.qrcodethumb), { order: "desc", sort: "tableno" }).reverse())
        } else {
            setTables([])
        }
    }, [loadTables])

    const columnsTable: Array<Column<IHospDiningTable>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.order-type' />,
        accessor: 'qrcodeordertype',
        Cell: ({ ...props }) => <BasicCell data={optionOrderType.find(f => f.value == props.data[props.row.index].qrcodeordertype)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'qrcodetype',
        Cell: ({ ...props }) => <BasicCell data={optionQrType.find(f => f.value == props.data[props.row.index].qrcodetype)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.dining-table.id' />,
        accessor: 'tableno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tableno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.seat-capacity' />,
        accessor: 'seatcapacity',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].seatcapacity} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.expires-at' />,
        accessor: 'qrexpireat',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].qrexpireat} type="datetime" />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.qr-code' className="text-center" />,
        accessor: 'qrcodethumb',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].qrcodethumb}
            type="img"
            className="d-flex flex-center"
            onClick={() => setPreviewImage(props.data[props.row.index])} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="print" onClick={async () => {
                const res = await ApiService(`/fnb/hospdiningtables/${props.data[props.row.index].id}?select=qrcode`);
                const table = res.data
                if (!table.qrcode) return popupMessage({ icon: 'error', description: 'QR Code has not been generated yet' })
                printImage(table.qrcode)
            }} />
            <RowIcon action="download" onClick={async () => {
                const res = await ApiService(`/fnb/hospdiningtables/${props.data[props.row.index].id}?select=qrcode`);
                const table = res.data
                if (!table.qrcode) return popupMessage({ icon: 'error', description: 'QR Code has not been generated yet' })
                downloadFile(table.qrcode, `table-${props.data[props.row.index].tableno}`)
            }} />
            <RowIcon permission={permission} action="reset" onClick={async () => {
                try {
                    const { id, qrcodetype } = props.data[props.row.index]
                    if (qrcodetype == 'dynamic') {
                        setValueTable({
                            ...props.data[props.row.index]
                        })
                    } else {
                        const bodyData = await createdQRCode(props.data[props.row.index])
                        const result = await ApiService.put(`/fnb/hospdiningtables/${id}`, bodyData)
                        popupMessage({ icon: 'success', autoClose: true })
                        setTables(tables.map(m => m.id == id ? result.data : m))
                    }
                } catch (error) {
                    ApiShowError(error)
                }
            }} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                try {
                    const id = props.data[props.row.index].id
                    const bodyData = {
                        id: props.data[props.row.index].id,
                        qrcodeordertype: null,
                        qrcodetype: null,
                        expirationtime: null,
                        qrexpireat: null,
                        qrcodethumb: null,
                        qrcode: null,
                    }
                    if (props.data[props.row.index].qrcodethumb) {
                        const pathtofile = props.data[props.row.index].qrcodethumb
                        await ApiDeleteFile(`QRCODE${String(pathtofile).split('/QRCODE').at(1)}`)
                    }
                    if (props.data[props.row.index].qrcode) {
                        const pathtofile = props.data[props.row.index].qrcode
                        await ApiDeleteFile(`QRCODE${String(pathtofile).split('/QRCODE').at(1)}`)
                    }
                    await ApiService.put(`/fnb/hospdiningtables/${id}`, bodyData)
                    popupMessage({ icon: 'success', autoClose: true })
                    setTables(tables.filter(f => f.id != id))
                } catch (error) {
                    ApiShowError(error)
                }
            }} />
        </div>
    }]

    function addMinutes(date: Date, minutes: number) {
        date.setMinutes(date.getMinutes() + minutes)
        return date
    }

    async function createdQRCode(value: IHospDiningTable) {
        const valueTable = optionTables.find(f => f.tableno == value.tableno)
        if (!valueTable) return undefined
        const newDate = new Date()
        const expirationtime = value.expirationtime ? Number(value.expirationtime) : 0
        const qrexpireat = value.qrcodetype == 'dynamic' ? addMinutes(newDate, expirationtime) : null
        const { hash, salt } = generateSecureShortCode(valueTable.id)
        const createdcode = `${process.env.REACT_APP_ORDER_URL}/scan/` + hash

        const image_qrcode = await generateQRCode(createdcode, 500)
        const image_qrcodethumb = await generateQRCode(createdcode)
        const file_qrcode = base64ToFile(image_qrcode as string, 'qrcode.png')
        const file_qrcodethumb = base64ToFile(image_qrcodethumb as string, 'qrcodethumb.png')
        const uploadFiels = await ApiUploadFiles(value.id as string, 'QRCODE', [file_qrcode, file_qrcodethumb])

        return {
            id: valueTable.id,
            merchantid: dataMerchant?.id,
            qrcodeordertype: value.qrcodeordertype,
            qrcodetype: value.qrcodetype,
            expirationtime: expirationtime,
            qrexpireat: qrexpireat,
            qrcodethumb: uploadFiels.find((f: any) => f.fileName == 'qrcodethumb.png')?.url,
            qrcode: uploadFiels.find((f: any) => f.fileName == 'qrcode.png')?.url,
            scancode: hash
        }
    }

    const [filter, setFilter] = useState<IHospDiningTable | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IHospDiningTable[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    return <>
        <KTTable
            isLoading={loadTables}
            toolbar={<>
                <FilterDropdown
                    handleSubmit={handleSubmit}
                    onClose={() => {
                        setFilter(undefined)
                        reset({})
                    }}
                    onSubmit={(data) => setFilter(data)}>
                    <KTFormItem title='text.active' labelWidth="auto">
                        <label className="form-check form-switch form-check-custom">
                            <input className="form-check-input" type="checkbox" {...register("active")} />
                        </label>
                    </KTFormItem>
                </FilterDropdown>
                <KTButton permission={permission} action="generate" disabled={!dataMerchant?.id}
                    titleBtn={intl.formatMessage({ id: 'text.generate-qr-code' })}
                    onClick={() => {
                        if (!dataMerchant?.enableordertype || dataMerchant?.enableordertype?.length == 0) return popupMessage({ icon: "error", description: "Enable order type not found." })
                        setValueTable({
                            merchantid: null,
                            sectionid: null,
                            sectioncode: null,
                            tableno: null,
                            tablename: null,
                            seatcapacity: null,
                            mincapacity: null,
                            maxcapacity: null,
                            qrcodeordertype: 'dine-in',
                            qrcodetype: null,
                            expirationtime: null,
                            qrexpireat: null,
                            qrcodethumb: null,
                            qrcode: null,
                            active: true
                        })
                    }} />
                <KTButton permission={permission} action="new" disabled={!dataMerchant?.id} onClick={() => {
                    if (!dataMerchant?.enableordertype || dataMerchant?.enableordertype?.length == 0) return popupMessage({ icon: "error", description: "Enable order type not found." })
                    setValueTable({
                        merchantid: null,
                        sectionid: null,
                        sectioncode: null,
                        tableno: null,
                        tablename: null,
                        seatcapacity: null,
                        mincapacity: null,
                        maxcapacity: null,
                        qrcodeordertype: 'dine-in',
                        qrcodetype: null,
                        expirationtime: null,
                        qrexpireat: null,
                        qrcodethumb: null,
                        qrcode: null,
                        active: false
                    })
                }} />
            </>}
            columns={columnsTable}
            data={onFilter(tables)}
            pagination
            search
        />
        {previewImage && <ModalPrivewQRCode {...{ previewImage, setPreviewImage }} />}
        {valueTable && <ModalQRCode key={'ModalQRCode'} {...{
            permission,
            optionTables: optionTables.filter(f => !tables.map(m => m.id).includes(f.id)),
            valueData: valueTable,
            dataMerchant,
            handleClose: function () {
                setValueTable(undefined)
            },
            handleSave: async function (values: IHospDiningTable) {
                if (!!values.id && values.qrcodetype == 'dynamic') {
                    const { qrcodeordertype, qrcodetype, expirationtime } = values
                    const bodyData = await createdQRCode({ ...values, qrcodeordertype, qrcodetype, expirationtime });
                    const result = await ApiService.put(`/fnb/hospdiningtables/${values.id}`, bodyData)
                    popupMessage({ icon: 'success', autoClose: true })
                    setTables(tables.map(m => m.id == values.id ? result.data : m))
                    setValueTable(undefined)
                    return;
                }
                try {
                    if (values.active) {
                        const { qrcodeordertype, qrcodetype, expirationtime } = values
                        const bodyDatas = await Promise.all(optionTables.map(async (item): Promise<any> => {
                            return await createdQRCode({ ...item, qrcodeordertype, qrcodetype, expirationtime });
                        }));
                        const results = await ApiService.post(`/fnb/hospdiningtables/upsertmulti`, bodyDatas)
                        popupMessage({ icon: 'success', autoClose: true })
                        setTables([...results.data.createdItems, ...results.data.updatedItems])
                    } else {
                        const bodyData = await createdQRCode(values)
                        if (bodyData) {
                            const result = await ApiService.put(`/fnb/hospdiningtables/${values.id}`, bodyData)
                            popupMessage({ icon: 'success', autoClose: true })
                            setTables([result.data, ...tables])
                        }
                    }
                } catch (error) {
                    ApiShowError(error)
                }
                setValueTable(undefined)
            }
        }} />}
    </>
}

function ModalPrivewQRCode({ previewImage, setPreviewImage }: any) {
    const { data: dataTable } = apiFnb.useHospDiningTablesByQuery(`/${previewImage.id}?select=qrcode`)

    return <Modal show={previewImage != undefined} onHide={() => setPreviewImage(undefined)} centered>
        <Modal.Body className="p-0">
            <Modal.Header closeButton className="pb-0 border-0">
                <Modal.Title>{`${dataTable?.tablename}`}</Modal.Title>
            </Modal.Header>
            {dataTable?.qrcode && <Image src={dataTable?.qrcode || '#'} alt={'previewimage'} fluid className="rounded" />}
        </Modal.Body>
    </Modal>
}

function ModalQRCode({ valueData, handleClose, handleSave, optionTables, permission, dataMerchant }: any) {
    const isGenerate = valueData?.active
    const { register, control, handleSubmit, watch, reset, setValue, formState: { errors, isSubmitting } } = useForm<IHospDiningTable>({
        resolver: yupResolver(yup.object().shape({
            tableno: yup.string().test('check', (val, ctx) => {
                if (!val && !isGenerate) return ctx.createError()
                return true
            }).nullable(true).transform(val => val || null),
            qrcodeordertype: yup.string().required(),
            qrcodetype: yup.string().required(),
            expirationtime: yup.number()
                .test('require_by_type', (val, ctx) => {
                    if (!val && ctx.parent['qrcodetype'] == 'dynamic') return ctx.createError()
                    return true
                })
                .nullable(true).transform(val => val ? Number(val) : null)
        }))
    })

    useEffect(() => {
        if (valueData) reset(valueData)
    }, [valueData])

    return <Modal show={valueData != undefined} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{!!valueData?.id ? 'Edit' : isGenerate ? 'Generate' : 'Add'} QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(handleSave)}>
                <KTFormItem title="Order Type" isHeight>
                    <div className="d-flex flex-wrap gap-2 w-100">
                        {Array.isArray(dataMerchant?.enableordertype) && dataMerchant?.enableordertype.map((m: any) => <label key={m.code} className="form-check form-check-sm form-check-custom gap-1">
                            <input className="form-check-input" type="radio" value={m.code} {...register('qrcodeordertype')} />
                            <span className="text-nowrap">{m.name}</span>
                        </label>)}
                    </div>
                </KTFormItem>
                {!isGenerate && <KTFormItem title="Select Table">
                    <Controller
                        control={control}
                        name='tableno'
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionTables}
                            value={optionTables.find((f: any) => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue('id', e.id)
                            }}
                            className={clsx({ 'form-error': errors.tableno })}
                        />}
                    />
                </KTFormItem>}
                <KTFormItem title="QR Code Type">
                    <Controller
                        control={control}
                        name='qrcodetype'
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionQrType}
                            value={optionQrType.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx({ 'form-error': errors.qrcodetype })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="Expiration time (min.)">
                    <input disabled={watch('qrcodetype') != 'dynamic'} className={clsx("form-control form-control-sm", { "form-error": errors.expirationtime })} {...register("expirationtime")} />
                </KTFormItem>
                {isGenerate && <span className="text-gray-600">
                    Kindly be aware that carrying out this action will result in the replacement of the current data on the table.
                </span>}
                <div className="mt-6 d-flex flex-end">
                    <FormSubmit
                        type={true}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}

function OrderSettingsForm({ dataMerchant, urlApi, refetch, navigate, permission }: IDataTab) {
    const { data: storeTierData, isSuccess: isStoreTier } = apiAd.useStoreTiersByID<IConfigStoreTier>(dataMerchant?.storetierid as string)
    const optionOrderType = isStoreTier ? storeTierData?.ordertypes : []
    const intl = useIntl()
    const { isEdit, valueid } = getValueId(dataMerchant?.id)
    const { register, control, reset, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm<ISMerchant>({
        defaultValues,
        resolver: yupResolver(yup.object().shape({
            enableordertype: yup
                .array()
                .of(yup.object().shape({
                    minimumqty: yup.number().nullable(true).transform(val => val ? Number(val) : null),
                    minimumamount: yup.number().nullable(true).transform(val => val ? Number(val) : null),
                    leadtime: yup.number().nullable(true).transform(val => val ? Number(val) : null)
                })),
        })),
    })
    const EnableOrderTypeisArray = Array.isArray(watch('enableordertype'))
    const [tabid, setTabId] = useState<string | undefined>(undefined)

    const optionCoverExceedsCapacitys = [
        { value: true, label: <FormattedMessage id="text.allowed" /> },
        { value: false, label: <FormattedMessage id="text.not-allowed" /> },
    ]

    useEffect(() => {
        if (dataMerchant && isStoreTier) {
            const enableordertype = dataMerchant.enableordertype?.filter(f => optionOrderType.map(m => m.code).includes(f.code)) || []
            reset({ ...dataMerchant, enableordertype })
            if (Array.isArray(enableordertype) && enableordertype[0])
                setTabId(enableordertype[0].code as any)
        }
    }, [dataMerchant, isStoreTier])

    async function onSubmit(data: ISMerchant) {
        try {
            const bodyData = {
                id: data.id,
                enableordertype: data.enableordertype,
                requestnoofguest: data.requestnoofguest,
                useseatingcapacity: data.useseatingcapacity,
                coverexceedcapacity: data.coverexceedcapacity
            }
            const method = isEdit ? 'put' : 'post'
            await ApiService[method](`${urlApi}${isEdit ? `/${valueid}` : ''}`, bodyData)
            popupMessage({ icon: 'success', autoClose: true })
            refetch()
            // navigate('/fnb/merchant-list')
        } catch (error) { ApiShowError(error) }
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gx-10 mb-10">
            <div className="col-12 col-lg-4">
                <div className="fs-4 py-2">
                    <FormattedMessage id="text.enable-order-type" />
                </div>
                <p><FormattedMessage id="text.enable-order-type-options" /></p>
                <div className="d-flex flex-column gap-8 mt-10">
                    {optionOrderType.map(typeStoreTier => <div key={typeStoreTier.code} className="d-flex flex-stack">
                        <span>
                            <div className="h4 fw-light">{typeStoreTier.name}</div>
                            {intl.formatMessage({ id: "text.permission-for-key-order-type" }, { key: typeStoreTier.name })}
                        </span>
                        <label className="form-check form-switch form-check-custom form-check-solid gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={EnableOrderTypeisArray
                                    && watch('enableordertype')?.find((f: any) => f.code == typeStoreTier.code)
                                    ? true : false}
                                onChange={(evt) => {
                                    const isTemp = EnableOrderTypeisArray && watch('enableordertype')?.find((f: any) => f.code == typeStoreTier.code)
                                    if (evt.target.checked && !isTemp) {
                                        setValue('enableordertype', [...watch('enableordertype'), {
                                            code: typeStoreTier.code,
                                            name: typeStoreTier.name,
                                            minimumqty: typeStoreTier.minimumqty,
                                            minimumamount: typeStoreTier.minimumamount,
                                            leadtime: typeStoreTier.leadtimeminutes,
                                        }])
                                    } else {
                                        setValue('enableordertype', watch('enableordertype').filter((f: any) => f.code != typeStoreTier.code))
                                    }
                                    if (watch('enableordertype').length > 0 && !tabid && !!watch('enableordertype')[0].code) {
                                        setTabId(watch('enableordertype')[0].code as string)
                                    }
                                    return evt
                                }} />
                        </label>
                    </div>)}
                </div>
            </div>
            <div className="col-12 col-lg-8">
                {EnableOrderTypeisArray && <Tabs
                    defaultActiveKey={tabid}
                    variant="underline"
                    onSelect={(k) => setTabId(k as string)}>
                    {watch('enableordertype').map((m, ind: number) => m.code
                        ? <Tab key={ind} eventKey={m.code} title={optionOrderType.find(f => f.code == m.code)?.name} >
                            <KTFormItem title="text.minimum-quantity">
                                <input
                                    {...register(`enableordertype.${ind}.minimumqty`)}
                                    className={clsx("form-control form-control-sm", { 'form-error': errors.enableordertype && errors.enableordertype[ind]?.minimumqty })}
                                />
                            </KTFormItem>
                            <KTFormItem title="text.minimum-amount">
                                <Controller
                                    control={control}
                                    name={`enableordertype.${ind}.minimumamount`}
                                    render={({ field: { value, onChange } }) => <InputPrice
                                        value={value}
                                        onChange={onChange}
                                        className={clsx({ 'form-error': errors.enableordertype && errors.enableordertype[ind]?.minimumamount })}
                                    />}
                                />
                            </KTFormItem>
                            <KTFormItem title="text.lead-time-(minutes)">
                                <input
                                    {...register(`enableordertype.${ind}.leadtime`)}
                                    className={clsx("form-control form-control-sm", { 'form-error': errors.enableordertype && errors.enableordertype[ind]?.leadtime })} />
                            </KTFormItem>
                        </Tab>
                        : <></>)}
                </Tabs>}
            </div>
        </div>
        <div>
            <div className="h4 fw-light"><FormattedMessage id="text.advance-settings" /></div>
            <div className="d-flex flex-stack">
                <label className="form-check form-check-sm form-check-custom form-check-solid gap-2">
                    <input className="form-check-input" type="checkbox" {...register("requestnoofguest")} />
                    <FormattedMessage id="text.request-no-of-guest" />
                </label>
                <label className="form-check form-check-sm form-check-custom form-check-solid gap-2">
                    <input className="form-check-input" type="checkbox" {...register("useseatingcapacity")} />
                    <FormattedMessage id="text.use-seating-capacity" />
                </label>
                <KTFormItem title="text.cover-exceeds-capacity" labelClassName="min-w-150px">
                    <Controller
                        control={control}
                        name='coverexceedcapacity'
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionCoverExceedsCapacitys}
                            value={optionCoverExceedsCapacitys.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx('w-200px', { 'form-error': errors.coverexceedcapacity })}
                        />}
                    />
                </KTFormItem>
            </div>
        </div>
        <div className="mt-6 d-flex flex-stack align-items-center">
            <TextFormByUser data={dataMerchant} />
            <FormSubmit
                type={isEdit}
                isSubmitting={isSubmitting}
                permission={permission}
                handleClose={() => navigate('/fnb/merchant-list')} />
        </div>
    </form>
}

function PrinterSettingsForm({ dataMerchant, permission }: IDataTab) {
    const urlquery = dataMerchant?.id ? `?merchantid=${dataMerchant?.id}` : undefined
    const { data: dataMerchantPrinters, isSuccess: isMerchantPrinters, isFetching: loadMerchantPrinters } = apiAd.usePrinterListsByQuery(urlquery)
    const [dataTable, setDataTable] = useState<ISMerchantPrinter[]>([])
    const [valueData, setValueData] = useState<ISMerchantPrinter | undefined>()

    useEffect(() => {
        if (!loadMerchantPrinters && isMerchantPrinters) {
            setDataTable(dataMerchantPrinters)
        } else {
            setDataTable([])
        }
    }, [loadMerchantPrinters])

    const columnsTable: Array<Column<ISMerchantPrinter>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => setValueData(props.data[props.row.index])}
            data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'type',
        Cell: ({ ...props }) => <BasicCell data={optionPrinterType.find(f => f.value == props.data[props.row.index].type)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.hardware-station-host' />,
        accessor: 'host',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].host + ':' + props.data[props.row.index].port} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" onClick={() => setValueData(props.data[props.row.index])} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`settings/printerlists/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setDataTable(pre => pre.filter(f => f.id != id))
            }} />
        </div>
    }]

    const [filter, setFilter] = useState<ISMerchantPrinter | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: ISMerchantPrinter[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    return <>
        <KTTable
            isLoading={loadMerchantPrinters}
            toolbar={<>
                <FilterDropdown
                    handleSubmit={handleSubmit}
                    onClose={() => {
                        setFilter(undefined)
                        reset({})
                    }}
                    onSubmit={(data) => setFilter(data)}>
                    <KTFormItem title='text.active' labelWidth="auto">
                        <label className="form-check form-switch form-check-custom">
                            <input className="form-check-input" type="checkbox" {...register("active")} />
                        </label>
                    </KTFormItem>
                </FilterDropdown>
                <KTButton permission={permission} action="new" disabled={!dataMerchant?.id} onClick={() => {
                    setValueData({
                        merchantid: dataMerchant?.id as string,
                        code: null,
                        name: null,
                        type: null,
                        host: '127.0.0.1',
                        port: null,
                        serverhttps: null,
                        logosize: null,
                        logoalign: null,
                        qrcodewidth: null,
                        qrcodeheight: null,
                        active: false
                    })
                }} />
            </>}
            columns={columnsTable}
            data={onFilter(dataTable)}
            pagination
            search
        />
        {valueData && <ModalPrinter key={'ModalPrinter'} {...{
            dataTable,
            permission,
            valueData,
            handleClose: function () { setValueData(undefined) },
            handleSave: async function (values: ISMerchantPrinter, isEdit: boolean) {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`settings/printerlists${isEdit ? `/${values.id}` : ''}`, changeDataModify(values))
                    setDataTable(isEdit ? dataTable.map(m => m.id == values.id ? values : m) : [result.data, ...dataTable])
                    popupMessage({ icon: 'success', autoClose: true })
                    setValueData(undefined)
                } catch (error) { ApiShowError(error) }
            }
        }} />}
    </>
}

const optionPrinterType = [
    { value: "windows", label: <FormattedMessage id="text.windows" /> },
    { value: "winprinter", label: <FormattedMessage id="text.win-printer" /> },
    { value: "oposprinter", label: <FormattedMessage id="text.opos-printer" /> },
]

const optionPrinterSize = [
    { value: "normal", label: <FormattedMessage id="text.normal" /> },
    { value: "double-wide", label: <FormattedMessage id="text.double-wide" /> },
    { value: "double-high", label: <FormattedMessage id="text.double-high" /> },
    { value: "double-wide-and-high", label: <FormattedMessage id="text.double-wide-and-high" /> },
]

const optionPrinterAlign = [
    { value: "left", label: <FormattedMessage id="text.left" /> },
    { value: "center", label: <FormattedMessage id="text.center" /> },
    { value: "right", label: <FormattedMessage id="text.right" /> }
]

function ModalPrinter({ dataTable, valueData, handleClose, handleSave, permission }: any) {
    const { isEdit, valueid } = getValueId(valueData?.id)
    const { watch, control, register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<ISMerchantPrinter>({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test('unique', (val, ctx) => {
                if (val && val != valueData?.code && dataTable.map((m: any) => m.code).includes(val)) return ctx.createError()
                return true
            }).required(),
            name: yup.string().required(),
            type: yup.string().required(),
            host: yup.string().required(),
            port: yup.number().min(0).max(65535).required(),
        }))
    })

    useEffect(() => {
        if (valueData) reset(valueData)
    }, [valueData])

    return <Modal show={valueData != undefined} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{valueData?.id ? <FormattedMessage id="text.edit" /> : <FormattedMessage id="text.add" />} <FormattedMessage id="text.printer" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit((data) => handleSave(data, isEdit))}>
                <KTFormItem title="text.id">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.code })} {...register("code")} />
                </KTFormItem>
                <KTFormItem title="text.name">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.name })} {...register("name")} />
                </KTFormItem>
                <KTFormItem title="text.type">
                    <Controller
                        name="type"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionPrinterType}
                            value={optionPrinterType.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx({ "form-error": errors.type })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="text.host">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.host })} {...register("host")} />
                </KTFormItem>
                <KTFormItem title="text.port">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.port })} {...register("port")} type='number' />
                </KTFormItem>
                {/* <KTFormItem title="text.logo-size">
                    <Controller
                        name="logosize"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionPrinterSize}
                            value={optionPrinterSize.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx({ "form-error": errors.logosize })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="text.logo-align">
                    <Controller
                        name="logoalign"
                        control={control}
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionPrinterAlign}
                            value={optionPrinterAlign.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx({ "form-error": errors.logoalign })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="text.qr-code-width">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.qrcodewidth })} {...register("qrcodewidth")} />
                </KTFormItem>
                <KTFormItem title="text.qr-code-height">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.qrcodeheight })} {...register("qrcodeheight")} />
                </KTFormItem> */}
                <KTFormItem title="text.server-https">
                    <label className="form-check form-check-sm form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register('serverhttps')} />
                    </label>
                </KTFormItem>
                <KTFormItem title="text.active">
                    <label className="form-check form-check-sm form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register('active')} />
                    </label>
                </KTFormItem>
                <div className="mt-6 d-flex flex-stack">
                    <TextFormByUser data={watch()} />
                    <FormSubmit
                        hideCloseButton
                        type={isEdit}
                        isSubmitting={isSubmitting}
                        permission={permission}
                        handleClose={handleClose} />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}

interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
    id?: string
    value: any
}

interface IStorePriceGroup {
    id: string
    code: string
    description: string
    priority: number
}

function StorePriceGroup({ dataMerchant, permission }: any) {
    const urlQuery = `fnb/storepricegroups`
    const headers = [
        { width: 150, key: 'code', name: 'Price Group Code', type: 'search' },
        { width: undefined, key: 'description', name: 'Price Group Description' },
        { width: 100, key: 'priority', name: 'Priority', type: 'number' },
    ]
    const { data: storePriceGroups, isFetching } = useQuery([`${urlQuery}?merchantid=${dataMerchant?.id}`], () => ApiService.get(`${urlQuery}?merchantid=${dataMerchant?.id}`).then(res => res.data), { enabled: !!dataMerchant?.id })
    const [dataSource, setDataSource] = useState<GridElement[][]>([])

    useEffect(() => {
        if (storePriceGroups && !isFetching) {
            const array: IStorePriceGroup[] = storePriceGroups
            setDataSource(array.map((item) => generateLine(item)))
        }
    }, [isFetching])

    async function deleteRow(indexToRemove: number) {
        const temp = dataSource[indexToRemove].at(0)
        if (temp?.id) {
            await ApiService.delete(`${urlQuery}/${temp?.id}`)
        }
        setDataSource((prevState) =>
            prevState.filter((row, index) => index !== indexToRemove)
        );
    }

    function generateLine(obj: any) {
        return headers.map(header => ({ value: obj[header.key], id: obj?.id }))
    }

    async function onCellsChanged(changes: any[], additions?: any[]) {
        const grid = dataSource.map((row) => [...row])
        const arraySaves: number[] = []
        changes.concat(additions || []).forEach(({ cell, row, col, value }) => {
            if (!grid[row] && value) {
                grid[row] = headers.map(col => ({ value: '' }))
            }
            if (grid[row] && grid[row][col]) {
                grid[row][col] = { ...grid[row][col], value }
            }
            if (!arraySaves.includes(row)) {
                arraySaves.push(row)
            }
        })
        let bodyData: { [key: string]: any }[] = grid
            .filter((f, i) => arraySaves.includes(i))
            .map((item) => {
                let line: { [key: string]: any } = {}
                const lineid = item.at(0)?.id
                if (lineid) {
                    line['id'] = lineid
                }
                if (!lineid && !item[0].value) {
                    return {};
                }
                headers.forEach((header, col) => {
                    if (!item[col].value && header && !lineid) {
                        if (header.key == 'description') {
                            line[header.key] = ''
                        } else if (header.key == 'priority') {
                            line[header.key] = 0
                        }
                    } else {
                        line[header.key] = typeof item[col].value == 'boolean' ? item[col].value : (item[col].value || null)
                    }
                })
                return { ...line, merchantid: dataMerchant.id }
            })
        bodyData = bodyData.filter(f => f && f.code)
        if (!bodyData.length) return;
        try {
            const results = await ApiService.post(`${urlQuery}/upsertmulti`, bodyData)
            const temps = results.data
            const girdPrev = grid.filter(f => f.at(0)?.id)
            setDataSource([...girdPrev, ...(temps.createdItems || []).map((m: any) => generateLine(m))])
        } catch (error) {
            ApiShowError(error)
        }
    }

    const isUpdate = permission && getIsPermission([permission, 'UPDATE'])
    const isDelete = permission && getIsPermission([permission, 'DELETE'])

    function renderGrirdValues() {
        const newRow = generateLine({})
        return [...onFilter(), newRow].map((rows, rowIndex) => {
            const temps: any[] = []
            rows.forEach((col: any, colIndex: number) => {
                const header = headers[colIndex]
                if (!header) return;
                const newCol: GridElement = { ...col, ...header }
                if (header.type == 'number') {
                    newCol['dataEditor'] = ({ onChange, cell, ...props }: any) => <input
                        {...props}
                        autoFocus
                        className="data-editor"
                        onChange={(e) => {
                            const val = e.target.value
                            if (val && Number(val) < -1) return;
                            onChange(Number(e.target.value))
                        }}
                    />
                } else if (header.type == 'search') {
                    newCol['readOnly'] = true
                    newCol['disableEvents'] = true
                }
                temps.push({ ...newCol, readOnly: !isUpdate } as GridElement)
            })
            return [
                ...temps,
                {
                    width: 50,
                    disableEvents: true,
                    readOnly: true,
                    forceComponent: true,
                    component: temps.at(0).id
                        ? <button disabled={!isDelete} className="btn btn-light px-2 py-1 text-center bg-transparent" onClick={() => deleteRow(rowIndex)}>
                            <i className="bi bi-trash3 p-0"></i>
                        </button>
                        : null
                } as GridElement
            ]
        })
    }

    const [text, setText] = useState("")
    const [filterValue, setFilterValue] = useState({ keyword: "" })

    function onFilter() {
        let tempsData = dataSource
        if (filterValue.keyword) {
            tempsData = dataSource.filter(cols => {
                return cols.some(col => {
                    return typeof col.value == 'string'
                        && col.value.toLowerCase().indexOf(filterValue.keyword.toLowerCase()) > -1
                })
            })
        }
        return tempsData
    }

    const onSearch = debounce(function (value: string) {
        setFilterValue({ keyword: value })
    }, 500)

    const [cellIndex, setCellIndex] = useState<{ row: number, col: number, value: any, id: any } | undefined>()
    const { data: customerPriceGroups } = useQuery(['fnb/customerpricegroups'], () => fetchQuery('customerpricegroups'))

    const optionAssignTos: any[] = useMemo(() => {
        let array: any[] = (customerPriceGroups ?? [])
        const codes = (dataSource ?? []).map((f: any) => f[0].value)
        array = array.filter(f => !codes.includes(f.code))
        return array.map((m: any) => ({ ...m, title: m.code, description: m.description }))
    }, [dataSource])

    return <React.Fragment>
        <div className="d-flex align-items-center mb-6">
            <input
                placeholder="Search"
                className="form-control w-200px me-auto"
                value={text}
                onChange={(e) => {
                    setText(e.target.value)
                    onSearch(e.target.value)
                }}
            />
        </div>
        <div className="table-responsive">
            <ReactDataSheet
                valueRenderer={(cell) => cell.value}
                data={renderGrirdValues()}
                sheetRenderer={(props: any) => {
                    return <table className={props.className + ' table table-bordered border-secondary m-0'}>
                        <thead>
                            <tr className="fw-bold text-gray-800">
                                {headers.map((header, ind: number) => (
                                    <th key={ind} className={clsx('ps-1')} style={{ width: header.width ? `${header.width}px` : undefined }}>
                                        {header.name}
                                    </th>
                                ))}
                                <th style={{ width: `50px` }}></th>
                            </tr>
                        </thead>
                        <tbody>{props.children || null}</tbody>
                    </table>
                }}
                cellRenderer={({ cell, row, col, className, updated, attributesRenderer, editing, ...props }: any) => {
                    return <td {...props} className={clsx(className, 'ps-1', { 'text-center': !cell.key, 'position-relative': cell.type == 'search' })}>
                        {props.children}
                        {cell.type == 'search' && !cell.readOnly && <div className="position-absolute top-0 end-0 h-100 d-flex flex-center cell-hover">
                            <button
                                className="btn p-0 h-20px w-20px me-2 bg-gray-100 border my-auto"
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    if (cell.key == 'code') {
                                        setCellIndex({ row, col, value: props.value, id: cell.id })
                                    }
                                }}
                            >
                                <i className="bi bi-search p-1 text-primary"></i>
                            </button>
                        </div>}
                    </td>
                }}
                onCellsChanged={onCellsChanged}
            />
        </div>
        <SearchPopup
            searchData={optionAssignTos}
            keys={cellIndex ? [['code'], ['description']] : []}
            selfSave={(data: any) => {
                const cellId = cellIndex?.id
                const rowIndex = cellIndex?.row
                const changes = []
                if (rowIndex != undefined) {
                    changes.push({ row: rowIndex, col: 0, cell: headers[0], value: data.code, id: cellId })
                    changes.push({ row: rowIndex, col: 1, cell: headers[1], value: data.description, id: cellId })
                }
                changes.length > 0 && onCellsChanged(changes)
                setCellIndex(undefined)
            }}
            setValue={() => { }}
            handleClose={function (): void {
                setCellIndex(undefined)
            }}
        />
    </React.Fragment>
}

interface IProductionSection {
    code: string
    name: string
    type: string
}

function ProductionSectionsForm({ dataMerchant, urlApi, refetch, permission }: IDataTab) {
    const [tableData, setTableData] = useState<IProductionSection[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [tableIndex, setTableIndex] = useState<number | null>(null)
    const { register, reset, control, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(yup.object().shape({
            code: yup.string().test('unique', 'Code must be unique', (val, ctx) => {
                if (val && tableData.length) {
                    if (tableIndex != null) {
                        return !tableData.some((m, i) => m.code == val && i != tableIndex)
                    } else {
                        return !tableData.some((m, i) => m.code == val)
                    }
                }
                return !!val
            }),
            name: yup.string().required(),
            type: yup.string().required(),
        }))
    })
    const column: Array<Column<any>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='text.id' />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell
            onClick={() => {
                reset(props.data[props.row.index])
                setIsOpen(true)
                setTableIndex(props.row.index)
            }}
            data={props.data[props.row.index].code}
        />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.type' />,
        accessor: 'type',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].type} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.name' />,
        accessor: 'name',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].name} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon
                permission={permission}
                action="modify"
                onClick={() => {
                    reset(props.data[props.row.index])
                    setIsOpen(true)
                    setTableIndex(props.row.index)
                }}
            />
            <RowIcon
                permission={permission}
                action="delete"
                onClick={async () => {
                    if (!dataMerchant) return;
                    const result = await ApiService.put(`${urlApi}/${dataMerchant.id}`, { productionsections: tableData.filter(f => f.code != props.data[props.row.index].code) })
                    setTableData(result.data.productionsections)
                    popupMessage({ icon: 'success', autoClose: true })
                    refetch()
                }}
            />
        </div>
    }]

    useEffect(() => {
        if (dataMerchant?.productionsections) setTableData(dataMerchant.productionsections)
    }, [dataMerchant])

    async function onSubmit(data: any) {
        if (!dataMerchant) return;
        try {
            const productionsections = tableIndex != null
                ? tableData.map((f, i) => i == tableIndex ? data : f)
                : [...tableData, data]
            const result = await ApiService.put(`${urlApi}/${dataMerchant.id}`, { productionsections })
            setTableData(result.data.productionsections)
            popupMessage({ icon: 'success', autoClose: true })
            refetch()
        } catch (error) {
            ApiShowError(error)
        }
        handleClose()
    }

    function handleClose() {
        reset({})
        setIsOpen(false)
        setTableIndex(null)
    }

    const optionTypes = [
        { label: 'BAR', value: 'BAR' },
        { label: 'KITCHEN', value: 'KITCHEN' },
        { label: 'RECEIPT', value: 'RECEIPT' },
        { label: 'PREPRINT', value: 'PREPRINT' }
    ]

    return <>
        <KTTable
            toolbar={<KTButton
                permission={permission}
                action="new"
                disabled={!dataMerchant?.id}
                onClick={() => {
                    reset({ code: '', name: '', type: '' })
                    setIsOpen(true)
                }}
            />}
            columns={column}
            data={tableData}
            pagination
            search
        />
        <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title> Edit Production Sections </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label className="form-label">ID</label>
                        <input className={clsx("form-control form-control-sm", { 'form-error': errors.code })} {...register("code")} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Type</label>
                        <Controller
                            control={control}
                            name="type"
                            render={({ field }) => <InputSelect
                                className={clsx('w-100', { 'form-error': errors.type })}
                                options={optionTypes}
                                value={optionTypes.find(f => f.value == field.value) ?? null}
                                onChange={(val) => field.onChange(val?.value)}
                            />}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input className={clsx("form-control form-control-sm", { 'form-error': errors.name })} {...register("name")} />
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
                        <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>Save change</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
}

interface ISectionDispStatMapping {
    hospdiningareasectionid: string | null
    hospdiningareasectionname: string | null
    productionsectioncode: string | null
    productionsectiontype: string | null
    productionsectionname: string | null
    displaystationtype: string
    printerid: string
    printercode: string
    printername: string
}

function SectionDispStatMappingsForm({ dataMerchant, urlApi, refetch, permission }: IDataTab) {
    const [tableData, setTableData] = useState<ISectionDispStatMapping[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [tableIndex, setTableIndex] = useState<number | null>(null)
    const { register, setValue, control, reset, handleSubmit, formState: { errors, isSubmitting } } = useForm<ISectionDispStatMapping>({
        resolver: yupResolver(yup.object().shape({
            hospdiningareasectionid: yup.string().required(),
            productionsectioncode: yup.string().required(),
            displaystationtype: yup.string().required(),
            printerid: yup.string().required()
        }))
    })

    const column: Array<Column<any>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Section' />,
        accessor: 'hospdiningareasectionname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].hospdiningareasectionname} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Prod. Section Code' />,
        accessor: 'productionsections',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].productionsectioncode} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Display Station Type' />,
        accessor: 'displaystationtype',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].displaystationtype} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Printer' />,
        accessor: 'printername',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].printername} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='text.actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon permission={permission} action="modify" onClick={() => {
                reset(props.data[props.row.index])
                setIsOpen(true)
                setTableIndex(props.row.index)
            }} />
            <RowIcon permission={permission} action="delete" onClick={async () => {
                if (!dataMerchant) return;
                const result = await ApiService.put(`${urlApi}/${dataMerchant.id}`, {
                    sectiondispstatmappings: tableData.filter((_, index) => index != props.row.index)
                })
                setTableData(result.data.sectiondispstatmappings)
                popupMessage({ icon: 'success', autoClose: true })
                refetch()
            }} />
        </div>
    }]

    useEffect(() => {
        if (dataMerchant?.sectiondispstatmappings) setTableData(dataMerchant.sectiondispstatmappings)
    }, [dataMerchant])

    const displayStationTypesOption = [{ value: 'Printer', label: 'Printer' }]
    const productionSectionsOption = (dataMerchant?.productionsections ?? []).map((m: any) => ({ value: m.code, label: m.name, type: m.type }))

    const { data: sectionsData } = apiFnb.useHospDiningAreaSectionsByQuery(dataMerchant?.id ? `?merchantid=${dataMerchant?.id}` : undefined)
    const sectionsOption = formatOptions(sectionsData, ['id', 'sectionname'])

    const { data: printersData } = apiAd.usePrinterListsByQuery(dataMerchant?.id ? `?merchantid=${dataMerchant?.id}` : undefined)
    const printersOption = formatOptions(printersData, ['id', 'code'])

    async function onSubmit(data: any) {
        if (!dataMerchant) return;
        try {
            const result = await ApiService.put(`${urlApi}/${dataMerchant.id}`, {
                sectiondispstatmappings: (tableIndex != null
                    ? tableData.map((f, i) => i == tableIndex ? data : f)
                    : [...tableData, data])
                    .map(m => ({
                        hospdiningareasectionid: m.hospdiningareasectionid,
                        hospdiningareasectionname: m.hospdiningareasectionname,
                        productionsectioncode: m.productionsectioncode,
                        productionsectiontype: m.productionsectiontype,
                        productionsectionname: m.productionsectionname,
                        displaystationtype: m.displaystationtype,
                        printerid: m.printerid,
                        printercode: m.printercode,
                        printername: m.printername
                    }))
            })
            setTableData(result.data.sectiondispstatmappings)
            popupMessage({ icon: 'success', autoClose: true })
            refetch()
        } catch (error) {
            ApiShowError(error)
        }
        handleClose()
    }

    function handleClose() {
        reset({})
        setIsOpen(false)
        setTableIndex(null)
    }

    return <>
        <KTTable
            toolbar={<KTButton
                permission={permission}
                action="new"
                disabled={!dataMerchant?.id}
                onClick={() => {
                    reset({ displaystationtype: 'Printer', printerid: '', printername: '' })
                    setIsOpen(true)
                }}
            />}
            columns={column}
            data={tableData}
            pagination
            search
        />
        <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Section Disp. Stat. Mappings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label className="form-label">Section</label>
                        <Controller
                            control={control}
                            name={'hospdiningareasectionid'}
                            render={({ field }) => <InputSelect
                                options={sectionsOption}
                                value={sectionsOption.find(f => f.value == field.value) ?? null}
                                onChange={(option) => {
                                    field.onChange(option?.value ?? null)
                                    setValue('hospdiningareasectionname', option?.label ?? null)
                                }}
                                className={clsx('w-100', { 'form-error': errors.hospdiningareasectionid })}
                            />}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Prod. Section</label>
                        <Controller
                            control={control}
                            name={'productionsectioncode'}
                            render={({ field }) => <InputSelect
                                options={productionSectionsOption}
                                value={productionSectionsOption.find((f: any) => f.value == field.value) ?? null}
                                onChange={(option) => {
                                    field.onChange(option?.value ?? null)
                                    setValue('productionsectiontype', option?.type ?? null)
                                    setValue('productionsectionname', option?.label ?? null)
                                }}
                                className={clsx('w-100', { 'form-error': errors.productionsectioncode })}
                            />}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Display Station Type</label>
                        <Controller
                            control={control}
                            name={'displaystationtype'}
                            render={({ field }) => <InputSelect
                                options={displayStationTypesOption}
                                value={displayStationTypesOption.find(f => f.value == field.value) ?? null}
                                onChange={(option) => {
                                    field.onChange(option?.value)
                                }}
                                className={clsx('w-100', { 'form-error': errors.displaystationtype })}
                            />}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Printer</label>
                        <Controller
                            control={control}
                            name={'printerid'}
                            render={({ field }) => <InputSelect
                                options={printersOption}
                                value={printersOption.find(f => f.value == field.value) ?? null}
                                onChange={(option) => {
                                    setValue('printerid', option?.id ?? null)
                                    setValue('printercode', option?.code ?? null)
                                    setValue('printername', option?.name ?? null)
                                }}
                                className={clsx('w-100', { 'form-error': errors.printerid })}
                            />}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Printer</label>
                        <input disabled className="form-control form-control-sm" {...register('printername')} />
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <button type="button" className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
                        <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>Save change</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
}
